import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  ISliderCreateRequest,
  ISliderCreateResponse,
  ISliderDetailResponse,
  ISliderEditRequest,
  ISliderFormDetail,
  ISliderListItem,
  ISliderListResponse,
} from "@/models/sliders";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface ISlidersState extends IEntitiesState {
  entities?: ISliderListItem[];
  sliderDetail: ISliderFormDetail | null;
}

const initialState: ISlidersState = {
  loading: "idle",
  error: null,
  success: null,
  sliderDetail: null,
};

export const fetchSliders = createAsyncThunk<
  ISliderListResponse,
  number,
  { rejectValue: IThunkCustomError }
>("sliders/fetchSliders", async (page, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.SLIDERS_URL(), {
      params: { page, pageSize: defaultPageSize },
    });
    return response.data as ISliderListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchSlider = createAsyncThunk<
  ISliderDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("sliders/fetchSlider", async (code, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.SLIDER_URL(code));
    return response.data as ISliderDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createSlider = createAsyncThunk<
  ISliderCreateResponse,
  ISliderCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "sliders/createSlider",
  async (request: ISliderCreateRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        backendPaths.SLIDER_CREATE_URL(),
        JSON.stringify(request)
      );
      void dispatch(fetchSliders(1));
      return response.data as ISliderCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const editSlider = createAsyncThunk<
  null,
  ISliderEditRequest,
  { rejectValue: IThunkCustomError }
>(
  "sliders/editSlider",
  async (doc: ISliderEditRequest, { rejectWithValue }) => {
    try {
      const docCode = doc.code;
      const request: PartialBy<ISliderEditRequest, "code"> = {
        ...doc,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.SLIDER_EDIT_URL(docCode),
        JSON.stringify(request)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteSlider = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("sliders/deleteSlider", async (coachCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.SLIDER_DELETE_URL(coachCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const toggleSlider = createAsyncThunk<
  ISliderDetailResponse,
  ISliderListItem,
  { rejectValue: IThunkCustomError }
>("sliders/toggleSlider", async (slider, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      backendPaths.SLIDER_TOGGLE_URL(slider.code)
    );
    await dispatch(fetchSliders(1));
    return response.data as ISliderDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const slidersAdapter = createEntityAdapter<ISliderListItem>({
  selectId: (item) => item.code,
});

const slidersSlice = createSlice({
  name: "sliders",
  initialState: slidersAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch sliders
      .addCase(fetchSliders.fulfilled, (state, action) => {
        slidersAdapter.removeAll(state);
        slidersAdapter.addMany(state, action.payload.sliders.items);
        state.pagination = action.payload.sliders.pagination;
      })
      // fetch one slider
      .addCase(fetchSlider.fulfilled, (state, action) => {
        state.sliderDetail = action.payload.slider;
      })
      // create slider
      .addCase(createSlider.fulfilled, (state) => {
        state.success = setSuccessMessage("Слайдер добавлен!");
      })
      // edit slider
      .addCase(editSlider.fulfilled, (state, action) => {
        state.sliderDetail = action.meta.arg;
        const update = {
          id: action.meta.arg.code,
          changes: {
            title: action.meta.arg.title,
            subTitle: action.meta.arg.subTitle,
            buttonTitle: action.meta.arg.buttonTitle,
            buttonLink: action.meta.arg.buttonLink,
          },
        };
        slidersAdapter.updateOne(state, update);
        state.success = setSuccessMessage("Слайдер обновлен");
      })
      // delete slider
      .addCase(deleteSlider.fulfilled, (state, action) => {
        slidersAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Слайдер удален", true);
      })
      // toggle one slider
      .addCase(toggleSlider.fulfilled, (state) => {
        state.success = setSuccessMessage("Статус слайдера изменен", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("sliders", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("sliders", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = slidersSlice.actions;
export const slidersSelectors = slidersAdapter.getSelectors<RootState>(
  (state: RootState) => state.sliders
);
export default slidersSlice.reducer;
