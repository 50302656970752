import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkCustomError } from "@/models/error";
import { IIconItem, IOptionIconsResponse } from "@/models/options";
import { IEntitiesState } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IOptionIconsState extends IEntitiesState {
  entities?: IIconItem[];
}

const initialState: IOptionIconsState = {
  loading: "idle",
  error: null,
  success: null,
};

export const fetchOptionIcons = createAsyncThunk<
  IOptionIconsResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("optionIcons/fetchOptionIcons", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.OPTION_ICONS_URL());
    return response.data as IOptionIconsResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const optionIconsAdapter = createEntityAdapter<IIconItem>({
  selectId: (item) => item,
});

const optionIconsSlice = createSlice({
  name: "optionIcons",
  initialState: optionIconsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptionIcons.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(fetchOptionIcons.fulfilled, (state, action) => {
        optionIconsAdapter.addMany(state, action.payload.icons.items);
        state.loading = "idle";
        state.error = null;
      })
      .addCase(fetchOptionIcons.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export const optionIconsSelectors = optionIconsAdapter.getSelectors<RootState>(
  (state: RootState) => state.optionIcons
);
export default optionIconsSlice.reducer;
