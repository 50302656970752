import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Radio,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { promocodeRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { ICreatePageState } from "@/models/pages";
import {
  IPromocodeCreateRequest,
  IPromocodeCreateValues,
} from "@/models/promocodes";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { fetchAllProducts } from "@/slices/productsSlice";
import { createPromocode } from "@/slices/promocodesSlice";
import setMessages from "@/utils/setMessages";

const { TextArea } = Input;

function PromocodeCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchAllProducts());
  }, []);

  const promocodeData = useAppSelector(({ promocodes }) => promocodes);
  const { loading, error, success } = promocodeData;

  const { allProducts } = useAppSelector(({ products }) => products);
  const productsOptions = allProducts?.map((item) => {
    return {
      value: item.code,
      label: item.title,
    };
  });

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([promocodeData]);

  const handleProgrammCreate = (values: IPromocodeCreateValues) => {
    const request: IPromocodeCreateRequest = {
      active: values.active,
      secret: values.secret,
      type: values.type,
      discount: values.discount,
      note: values.note,
      startAt: dayjs(values.startAt).format("YYYY-MM-DD"),
      endAt: dayjs(values.endAt).format("YYYY-MM-DD"),
      productCodes: values.productCodes,
    };
    void dispatch(createPromocode(request));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.promocode_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProgrammCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Активен"
            name="active"
            valuePropName="checked"
            initialValue
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Промокод"
            name="secret"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите промокод",
              },
              {
                required: true,
                pattern: new RegExp(promocodeRegExp),
                message:
                  "Допускаются только латинские или русские буквы, цифры и дефис",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип скидки"
            name="type"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите тип скидки",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="absolute">в рублях</Radio>
              <Radio value="percent">в процентах</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Скидка"
            name="discount"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите скидку в рублях",
              },
            ]}
          >
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Примечание"
            name="note"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Действует от"
            name="startAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату начала",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Действует до"
            name="endAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату окончания",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Акции"
            name="productCodes"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите акцию из списка",
              },
            ]}
          >
            <Select
              options={productsOptions}
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder="Введите название акции"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default PromocodeCreate;
