import { Button, Card, Form, PageHeader, Upload } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IClubEditPhotosValues, IClubsEditPhotosArgs } from "@/models/clubs";
import {
  convertIFileResponseToPhotoListItems,
  createInitUploadConfig,
} from "@/models/files";
import { IEditPageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import {
  editClubPhotos,
  fetchClub,
  fetchClubPhotos,
  resetErrors,
} from "@/slices/clubsSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

function ClubPhotoEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    if (param.id) {
      void dispatch(resetErrors());
      void dispatch(fetchClub(param.id));
      void dispatch(fetchClubPhotos(param.id));
    }
  }, []);

  const clubsData = useAppSelector(({ clubs }) => clubs);
  const { loading, error, success, clubDetail, clubPhotos } = clubsData;

  const initFile = createInitUploadConfig(clubPhotos);

  const messages = setMessages([clubsData]);

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }
    if (loading === "idle" && clubDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }
    if (error) {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }
    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [clubDetail, loading, error, success]);

  const handleUpdateClubAddress = (values: IClubEditPhotosValues) => {
    if (!param.id || !values.photo || !Array.isArray(values.photo)) return;

    const args: IClubsEditPhotosArgs = {
      code: param.id,
      photos: convertIFileResponseToPhotoListItems(values.photo),
    };
    void dispatch(editClubPhotos(args));
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return clubDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.club_photos_edit.title}: ${clubDetail.clubName}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="editClubPhotos"
          labelCol={form.LebelCol}
          wrapperCol={form.WrapperCol}
          autoComplete="off"
          initialValues={{
            photo: initFile,
          }}
          onFinish={handleUpdateClubAddress}
        >
          <Form.Item
            label="Фотографии клуба"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото клуба",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple
              maxCount={10}
              defaultFileList={initFile}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ClubPhotoEdit;
