import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import dayOfWeek from "@/constants/daysOfWeek";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IEditPageState } from "@/models/pages";
import { IScheduleCreateValues, IScheduleEditArgs } from "@/models/schedule";
import frontendPaths from "@/routes/paths";
import { fetchAllAreas, resetErrors } from "@/slices/clubsSlice";
import { coachsSelectors, fetchCoachs } from "@/slices/coachsSlice";
import { fetchProgramms, programmsSelectors } from "@/slices/programmsSlice";
import { editEvent, fetchEvent } from "@/slices/scheduleSlice";
import getFullName from "@/utils/getFullName";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ScheduleEdit() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const param = useParams();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    void dispatch(resetErrors());
    param.id && void dispatch(fetchEvent(param.id));
    void dispatch(fetchProgramms());
    void dispatch(fetchCoachs());
    void dispatch(fetchAllAreas());
  }, []);

  const scheduleData = useAppSelector(({ schedule }) => schedule);
  const { eventDetail, loading, error, success } = scheduleData;

  const programms = useAppSelector(programmsSelectors.selectAll);
  const programmsOptions = programms.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  const coachs = useAppSelector(coachsSelectors.selectAll);
  const coachsOptions = coachs.map((item) => {
    return {
      value: item.code,
      label: getFullName(item.firstName, item.lastName),
    };
  });

  const { clubAllAreas } = useAppSelector(({ clubs }) => clubs);
  const areasOptions = clubAllAreas?.map((item) => {
    return {
      value: item.code,
      label: `${item.name} (${item.club.name})`,
    };
  });

  useEffect(() => {
    if (loading === "loading") {
      setEditPageState(IEditPageState.loading);
    }
    if (loading === "idle" && eventDetail) {
      setEditPageState(IEditPageState.loaded);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }
    if (error && error.code === "404") {
      setEditPageState(IEditPageState.recordNotFound);
    }
    if (success) {
      setEditPageState(IEditPageState.success);
    }
  }, [eventDetail, loading, error, success]);

  const messages = setMessages([scheduleData]);

  const handleScheduleEdit = (values: IScheduleCreateValues) => {
    const teacher = coachs.find((item) => item.code === values.teacherCode);
    const area = clubAllAreas?.find((item) => item.code === values.areaCode);
    const program = programms.find((item) => item.code === values.programCode);
    if (param.id && teacher && area && program) {
      const request: IScheduleEditArgs = {
        code: param.id,
        hour: values.hour,
        minute: values.minute,
        weekday: values.weekday,
        paid: values.paid,
        comment: values.comment,
        teacher,
        area,
        program,
      };
      console.log(request);
      void dispatch(editEvent(request));
    }
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return eventDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.schedule_edit.title}: ${eventDetail.hour}:${eventDetail.minute}`}
        onBack={() => navigate(-1)}
      />
      {eventDetail && (
        <Card>
          {(isEditPageState === IEditPageState.error ||
            isEditPageState === IEditPageState.success) && (
            <ShowMessages messages={messages} />
          )}
          <Form
            name="editEvent"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            onFinish={handleScheduleEdit}
            initialValues={{
              hour: eventDetail.hour,
              minute: eventDetail.minute,
              programCode: eventDetail.programCode,
              teacherCode: eventDetail.teacherCode,
              areaCode: eventDetail.areaCode,
              weekday: eventDetail.weekday,
              paid: eventDetail.paid,
              comment: eventDetail.comment,
            }}
          >
            <Form.Item
              label="Время начала часы: минуты"
              style={{ marginBottom: "0" }}
            >
              <Input.Group>
                <Space>
                  <Form.Item
                    name="hour"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста укажите час",
                      },
                    ]}
                  >
                    <InputNumber min={0} max={23} />
                  </Form.Item>
                  <Form.Item
                    name="minute"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста укажите минуты",
                      },
                    ]}
                  >
                    <InputNumber min={0} max={59} />
                  </Form.Item>
                </Space>
              </Input.Group>
            </Form.Item>

            <Form.Item
              label="День недели"
              name="weekday"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите название программы",
                },
              ]}
            >
              <Select options={dayOfWeek} />
            </Form.Item>

            <Form.Item
              label="Программа"
              name="programCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите программу",
                },
              ]}
            >
              <Select options={programmsOptions} />
            </Form.Item>

            <Form.Item
              label="Тренер"
              name="teacherCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите количество калорий",
                },
              ]}
            >
              <Select options={coachsOptions} />
            </Form.Item>

            <Form.Item
              label="Зал и клуб"
              name="areaCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите зал и клуб",
                },
              ]}
            >
              <Select options={areasOptions} />
            </Form.Item>

            <Form.Item
              label="Платное участие"
              name="paid"
              valuePropName="checked"
            >
              <Checkbox>Да</Checkbox>
            </Form.Item>

            <Form.Item label="Комментарии" name="comment">
              <TextArea rows={6} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  ) : null;
}

export default ScheduleEdit;
