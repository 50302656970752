import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import ShowMessages from "@/components/ShowMessages";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IDocsListItem } from "@/models/docs";
import IPageState from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { deleteDoc, docsSelectors, fetchDocs } from "@/slices/docsSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function Docs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);
  const [isDocDeleteMode, setDocDeleteMode] = useState(false);
  const [removableDoc, setRemovableDoc] = useState<IDocsListItem | null>(null);

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchDocs());
  }, []);

  const docsData = useAppSelector(({ docs }) => docs);
  const { loading, success, error } = docsData;

  const docs = addReactKey<IDocsListItem>(
    useAppSelector(docsSelectors.selectAll)
  );

  const messages = setMessages([docsData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && docs) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error]);

  // delete document
  const handleTurnOnDocDeleteMode = (code: string) => {
    const docToRemove = docs.find((item) => item.code === code);
    if (docToRemove) {
      setDocDeleteMode(true);
      setRemovableDoc(docToRemove);
    }
  };

  const handleDeleteDocSubmit = (code: string) => {
    void dispatch(deleteDoc(code));
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const handleDeleteDocCancel = () => {
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const columns: ColumnProps<IDocsListItem>[] = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      width: "100%",
    },
    {
      title: "Показывать на главной",
      dataIndex: "mainPageShow",
      key: "mainPageShow",
      width: "10%",
      align: "center",
      render: (_, record) => record.mainPageShow && <CheckCircleOutlined />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.doc_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnDocDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Документы"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.doc_create.URL()}>
            <Button type="primary">{frontendPaths.doc_create.title}</Button>
          </Link>
        }
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={docs}
          columns={columns}
          loading={isPageState === IPageState.loading}
        />
      </Card>
      {removableDoc && (
        <DeleteConfirmModal
          isOpen={isDocDeleteMode}
          onOk={() => handleDeleteDocSubmit(removableDoc.code)}
          onCancel={handleDeleteDocCancel}
          text={`Удалить документ: ${removableDoc.name}?`}
        />
      )}
    </>
  );
}

export default Docs;
