import { Layout } from "antd";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import Logo from "@/components/Logo";

type LayoutTemplateProperties = {
  children: ReactNode;
};

const { Header, Content, Footer } = Layout;

function PublicLayoutTemplate({ children }: LayoutTemplateProperties) {
  return (
    <Layout className="layout">
      <Header>
        <Link to="/">
          <Logo />
        </Link>
      </Header>
      <Content>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer className="site-footer">© Interso 2023</Footer>
    </Layout>
  );
}

export default PublicLayoutTemplate;
