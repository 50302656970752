import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkCustomError } from "@/models/error";
import {
  convertScheduleCreateArgsToDetail,
  convertScheduleCreateArgsToListItem,
  IScheduleCreateArgs,
  IScheduleCreateRequest,
  IScheduleCreateResponse,
  IScheduleDetail,
  IScheduleDetailResponse,
  IScheduleEditArgs,
  IScheduleEditRequest,
  IScheduleListItem,
  IScheduleListResponse,
} from "@/models/schedule";
import { FilterType, IEntitiesState, ISorterRusult } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IScheduleState extends IEntitiesState {
  entities?: IScheduleListItem;
  eventDetail: IScheduleDetail | null;
  filters: FilterType;
  sorter: ISorterRusult | ISorterRusult[];
}

const initialState: IScheduleState = {
  loading: "idle",
  error: null,
  success: null,
  eventDetail: null,
  filters: {},
  sorter: {},
};

// list
export const fetchEvents = createAsyncThunk<
  IScheduleListResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("schedule/fetchEvents", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.SCHEDULE_LIST_URL());
    return response.data as IScheduleListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const deleteEvent = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("schedule/deleteEvent", async (eventCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.SCHEDULE_DELETE_URL(eventCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

// detail
export const createEvent = createAsyncThunk<
  IScheduleCreateResponse,
  IScheduleCreateArgs,
  { rejectValue: IThunkCustomError }
>(
  "schedule/createEvent",
  async (args: IScheduleCreateArgs, { rejectWithValue }) => {
    const request: IScheduleCreateRequest = {
      hour: args.hour,
      minute: args.minute,
      weekday: args.weekday,
      programCode: args.program.code,
      teacherCode: args.teacher.code,
      areaCode: args.area.code,
      paid: args.paid,
      comment: args.comment,
    };
    try {
      const response = await axios.post(
        backendPaths.SCHEDULE_CREATE_URL(),
        JSON.stringify(request)
      );
      return response.data as IScheduleCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const fetchEvent = createAsyncThunk<
  IScheduleDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("schedule/fetchEvent", async (eventCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.SCHEDULE_URL(eventCode));
    return response.data as IScheduleDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editEvent = createAsyncThunk<
  null,
  IScheduleEditArgs,
  { rejectValue: IThunkCustomError }
>(
  "schedule/editEvent",
  async (args: IScheduleEditArgs, { rejectWithValue }) => {
    try {
      const eventCode = args.code;
      const request: IScheduleEditRequest = {
        hour: args.hour,
        minute: args.minute,
        weekday: args.weekday,
        programCode: args.program.code,
        teacherCode: args.teacher.code,
        areaCode: args.area.code,
        paid: args.paid,
        comment: args.comment,
      };
      const response = await axios.post(
        backendPaths.SCHEDULE_EDIT_URL(eventCode),
        JSON.stringify(request)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

const scheduleAdapter = createEntityAdapter<IScheduleListItem>({
  selectId: (item) => item.code,
});

const scheduleSlice = createSlice({
  name: "schedule",
  initialState: scheduleAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
    setFilters: (state, action: PayloadAction<FilterType>) => {
      state.filters = action.payload;
    },
    setSorter: (
      state,
      action: PayloadAction<ISorterRusult | ISorterRusult[]>
    ) => {
      state.sorter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch events
      .addCase(fetchEvents.fulfilled, (state, action) => {
        scheduleAdapter.addMany(state, action.payload.schedule.items);
      })
      // delete event
      .addCase(deleteEvent.fulfilled, (state, action) => {
        scheduleAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Событие удалено!", true);
      })
      // create event in schedule
      .addCase(createEvent.fulfilled, (state, action) => {
        state.eventDetail = convertScheduleCreateArgsToDetail(action.meta.arg);
        const eventListItem = convertScheduleCreateArgsToListItem(
          action.payload.code,
          action.meta.arg
        );
        scheduleAdapter.addOne(state, eventListItem);
        state.success = setSuccessMessage("Событие добавлено");
      })
      // fetch one event
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.eventDetail = convertScheduleCreateArgsToDetail(
          action.payload.schedule
        );
      })
      // edit event
      .addCase(editEvent.fulfilled, (state, action) => {
        state.eventDetail = convertScheduleCreateArgsToDetail(action.meta.arg);
        const update = {
          id: action.meta.arg.code,
          changes: {
            hour: action.meta.arg.hour,
            minute: action.meta.arg.minute,
            program: action.meta.arg.program,
            teacher: action.meta.arg.teacher,
            area: action.meta.arg.area,
            weekday: action.meta.arg.weekday,
            paid: action.meta.arg.paid,
            comment: action.meta.arg.comment,
          },
        };
        scheduleAdapter.updateOne(state, update);
        state.success = setSuccessMessage("Событие обновлено");
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("schedule", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("schedule", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors, setFilters, setSorter } = scheduleSlice.actions;
export const scheduleSelectors = scheduleAdapter.getSelectors<RootState>(
  (state: RootState) => state.schedule
);
export default scheduleSlice.reducer;
