import { Button, Card, Form, Input, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IEditPageState } from "@/models/pages";
import { ISliderEditRequest, ISliderEditValues } from "@/models/sliders";
import frontendPaths from "@/routes/paths";
import { editSlider, fetchSlider, resetErrors } from "@/slices/slidersSlice";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

function SliderEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && dispatch(fetchSlider(param.id));
  }, []);

  const sliderData = useAppSelector(({ sliders }) => sliders);
  const { sliderDetail, loading, error, success } = sliderData;

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && sliderDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [sliderDetail, loading, error, success]);

  console.log("slider detail: ", sliderDetail);

  const messages = setMessages([sliderData]);

  const handleSliderEdit = (values: ISliderEditValues) => {
    if (sliderDetail) {
      const request: ISliderEditRequest = {
        code: sliderDetail.code,
        ...values,
      };
      void dispatch(editSlider(request));
    }
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return sliderDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.slider_edit.title}: ${sliderDetail.title}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="editSlider"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleSliderEdit}
          initialValues={{
            title: sliderDetail.title,
            subTitle: sliderDetail.subTitle,
            buttonTitle: sliderDetail.buttonTitle,
            buttonLink: sliderDetail.buttonLink,
          }}
        >
          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Подзаголовок"
            name="subTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите подзаголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Название кнопки"
            name="buttonTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="URL кнопки"
            extra="Для формы 'отправить заявку' укажите #send-request"
            name="buttonLink"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите URL кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default SliderEdit;
