import { uniqueId } from "lodash";

// добавляет к любому массиву ключ key (нужен для рендеринга коллекций в react)
export function addReactKey<Type>(arr: Array<Type> | undefined) {
  if (!arr) return [];
  // eslint-disable-next-line
  return arr.map((obj: Type) => {
    // eslint-disable-next-line
    return {
      key: uniqueId(),
      ...obj,
    };
  });
}

export default addReactKey;
