import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import {
  IFormQuerieDetail,
  IFormQuerieDetailResponse,
  IFormQueriesListItem,
  IFormQueriesResponse,
} from "@/models/formQueries";
import { IEntitiesState, IPaginationParams } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IFormQueriesState extends IEntitiesState {
  entities?: IFormQueriesListItem[];
  formDetail: IFormQuerieDetail | null;
}

const initialState: IFormQueriesState = {
  loading: "idle",
  error: null,
  success: null,
  formDetail: null,
};

export const fetchformQueries = createAsyncThunk<
  IFormQueriesResponse,
  IPaginationParams,
  { rejectValue: IThunkCustomError }
>("formQueries/fetchformQueries", async (pagination, { rejectWithValue }) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: backendPaths.FORM_QUERIES_URL(),
    params: {
      page: pagination.page,
      pageSize: pagination.pageSize || defaultPageSize,
    },
  };
  try {
    const response = await axios(options);
    return response.data as IFormQueriesResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchformQuerie = createAsyncThunk<
  IFormQuerieDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("formQueries/fetchformQuerie", async (formCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.FORM_QUERIE_URL(formCode));
    return response.data as IFormQuerieDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const formQueriesAdapter = createEntityAdapter<IFormQueriesListItem>({
  selectId: (item) => item.code,
});

const formQueriesSlice = createSlice({
  name: "formQueries",
  initialState: formQueriesAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch forms
      .addCase(fetchformQueries.fulfilled, (state, action) => {
        formQueriesAdapter.removeAll(state);
        formQueriesAdapter.addMany(state, action.payload.clientQueries.items);
        state.pagination = action.payload.clientQueries.pagination;
      })
      // fetch detail form
      .addCase(fetchformQuerie.fulfilled, (state, action) => {
        state.formDetail = action.payload.clientQuery;
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("formQueries", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("formQueries", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = formQueriesSlice.actions;
export const formQueriesSelectors = formQueriesAdapter.getSelectors<RootState>(
  (state: RootState) => state.formQueries
);
export default formQueriesSlice.reducer;
