import { useRoutes } from "react-router-dom";

import LoadDirectories from "./LoadDirectories";
import frontendPaths from "./paths";
import PersistLogin from "./PersistLogin";
import RequireAuth from "./RequareAuth";

const Routes = () => {
  const publicPaths = Object.values(frontendPaths)
    .filter((item) => item.public === true)
    .map((item) => {
      return {
        path: item.URL(),
        element: item.element,
      };
    });

  const privatePaths = Object.values(frontendPaths)
    .filter((item) => item.public === false)
    .map((item) => {
      return {
        path: item.URL(),
        element: item.element,
      };
    });

  return useRoutes([
    ...publicPaths,
    {
      element: <PersistLogin />,
      children: [
        {
          element: <RequireAuth />,
          children: [
            {
              element: <LoadDirectories />,
              children: privatePaths,
            },
          ],
        },
      ],
    },
  ]);
};

export default Routes;
