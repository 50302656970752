import { Button, Card, Form, Input, InputNumber, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { ICreatePageState } from "@/models/pages";
import { IProgrammEditValues } from "@/models/programms";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { createProgramm } from "@/slices/programmsSlice";
import setMessages from "@/utils/setMessages";

function ProgrammCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { TextArea } = Input;

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const programmsData = useAppSelector(({ programms }) => programms);
  const { loading, error, success } = programmsData;

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([programmsData]);

  const handleProgrammCreate = (values: IProgrammEditValues) => {
    void dispatch(createProgramm(values));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.programm_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProgrammCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Название программы"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название программы",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Продолжительность, мин"
            name="duration"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите продолжительность программы",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Калорий"
            name="calories"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите количество калорий",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Краткое описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите краткое описание программы",
              },
            ]}
          >
            <TextArea rows={4} showCount maxLength={100} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ProgrammCreate;
