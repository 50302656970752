import { Button, Card, Checkbox, Form, Input, PageHeader, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IDocEditValues } from "@/models/docs";
import { ICreatePageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { createDoc } from "@/slices/docsSlice";
import setMessages from "@/utils/setMessages";

export const docsColorOptions = [
  {
    value: "red",
    label: "Красный",
  },
  {
    value: "white",
    label: "Белый",
  },
];

function DocCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const docsData = useAppSelector(({ docs }) => docs);
  const { loading, error, success } = docsData;

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([docsData]);

  const handleDocCreate = (values: IDocEditValues) => {
    void dispatch(createDoc(values));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.doc_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleDocCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Заголовок"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок документа",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите slug",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Только латинские буквы и цифры",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Цвет"
            name="color"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цвет ярлыка документа",
              },
            ]}
          >
            <Select options={docsColorOptions} />
          </Form.Item>

          <Form.Item
            label="Показывать на главной?"
            name="mainPageShow"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание программы",
              },
            ]}
          >
            <TextEditor
              value="description"
              readonly={isCreatePageState === ICreatePageState.success}
            />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default DocCreate;
