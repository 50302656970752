import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import {
  covertICoachCreatedArgs2IcoachListItem,
  covertICoachEditArgs2IcoachDetail,
  covertICoachEditArgs2IcoachListItem,
  ICoachCreateArgs,
  ICoachCreateRequest,
  ICoachCreateResponse,
  ICoachDetail,
  ICoachEditArgs,
  ICoachEditRequest,
  ICoachListItem,
  ICoachResponse,
  ICoachsResponse,
} from "@/models/coachs";
import { IThunkCustomError } from "@/models/error";
import {
  FilterType,
  IEntitiesState,
  ISorterRusult,
  PartialBy,
} from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface ICoachsState extends IEntitiesState {
  entities?: ICoachListItem[];
  coachDetail: ICoachDetail | null;
  filters: FilterType;
  sorter: ISorterRusult | ISorterRusult[];
}

const initialState: ICoachsState = {
  loading: "idle",
  error: null,
  success: null,
  coachDetail: null,
  filters: {},
  sorter: {},
};

// list
export const fetchCoachs = createAsyncThunk<
  ICoachsResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("coachs/fetchCoachs", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.COACHS_URL());
    return response.data as ICoachsResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createCoach = createAsyncThunk<
  ICoachCreateResponse,
  ICoachCreateArgs,
  { rejectValue: IThunkCustomError }
>(
  "coachs/createCoach",
  async (request: ICoachCreateArgs, { rejectWithValue }) => {
    const clubs = request.clubs.map((item) => item.code);
    const backendRequest: ICoachCreateRequest = {
      ...request, // лучше так не делать - нужно преобразование полное
      photoCode: request.photo.code,
      clubCodes: clubs,
    };
    try {
      const response = await axios.post(
        backendPaths.COACH_CREATE_URL(),
        JSON.stringify(backendRequest)
      );
      return response.data as ICoachCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteCoach = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("coachs/deleteCoach", async (coachCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.COACH_DELETE_URL(coachCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

// detail
export const fetchCoach = createAsyncThunk<
  ICoachResponse,
  string,
  { rejectValue: IThunkCustomError }
>("coachs/fetchCoach", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.COACH_URL(coachCode));
    return response.data as ICoachResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editCoach = createAsyncThunk<
  null,
  ICoachEditArgs,
  { rejectValue: IThunkCustomError }
>("coachs/editCoach", async (coach: ICoachEditArgs, { rejectWithValue }) => {
  try {
    const coachCode = coach.code;
    const request: PartialBy<ICoachEditArgs, "code"> = {
      ...coach,
    };
    delete request.code;
    const clubs = request.clubs.map((item) => item.code);
    const backendRequest: ICoachEditRequest = {
      ...request,
      photoCode: request.photo.code,
      clubCodes: clubs,
    };
    const response = await axios.post(
      backendPaths.COACH_EDIT_URL(coachCode),
      JSON.stringify(backendRequest)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const coachsAdapter = createEntityAdapter<ICoachListItem>({
  selectId: (item) => item.code,
});

const coachsSlice = createSlice({
  name: "coachs",
  initialState: coachsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
    setFilters: (state, action: PayloadAction<FilterType>) => {
      state.filters = action.payload;
    },
    setSorter: (
      state,
      action: PayloadAction<ISorterRusult | ISorterRusult[]>
    ) => {
      state.sorter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch coachs
      .addCase(fetchCoachs.fulfilled, (state, action) => {
        coachsAdapter.addMany(state, action.payload.teachers.items);
      })
      // create coach
      .addCase(createCoach.fulfilled, (state, action) => {
        const newCoach: ICoachListItem = covertICoachCreatedArgs2IcoachListItem(
          action.payload.code,
          action.meta.arg
        );
        coachsAdapter.addOne(state, newCoach);
        state.success = setSuccessMessage("Тренер добавлен");
      })
      // delete coach
      .addCase(deleteCoach.fulfilled, (state, action) => {
        coachsAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Тренер удален");
      })
      // fetch one coach
      .addCase(fetchCoach.fulfilled, (state, action) => {
        state.coachDetail = action.payload.teacher;
      })
      // edit coach
      .addCase(editCoach.fulfilled, (state, action) => {
        state.coachDetail = covertICoachEditArgs2IcoachDetail(action.meta.arg);
        const updateCoach = {
          id: action.meta.arg.code,
          changes: covertICoachEditArgs2IcoachListItem(action.meta.arg),
        };
        coachsAdapter.updateOne(state, updateCoach);
        state.success = setSuccessMessage("Тренер обновлен");
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("coachs", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("coachs", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors, setFilters, setSorter } = coachsSlice.actions;
export const coachsSelectors = coachsAdapter.getSelectors<RootState>(
  (state: RootState) => state.coachs
);
export default coachsSlice.reducer;
