enum IPageState {
  loading = "loading",
  loaded = "loaded",
  error = "error",
  success = "success",
  recordNotFound = "404",
  deleted = "delited",
}

export default IPageState;

export enum ICreatePageState {
  unsent = "unsent",
  sending = "sending",
  error = "error",
  success = "success",
}

export enum IEditPageState {
  loading = "loading",
  loaded = "loaded",
  error = "error",
  success = "success",
  recordNotFound = "404",
}
