import { IProgramListItem } from "./programms";

type dayOfWeekCode = 0 | 1 | 2 | 3 | 4 | 5 | 6;

type ListTeacherItem = {
  code: string;
  firstName: string;
  lastName: string;
  phone: string;
  socialTG: string;
  socialVK: string;
};

type ListAreaItem = {
  code: string;
  name: string;
  club: {
    code: string;
    name: string;
    contactPhone: string;
  };
};

export interface IScheduleListItem {
  code: string;
  time: string;
  weekday: dayOfWeekCode;
  paid: boolean;
  comment: string;

  teacher: ListTeacherItem;
  area: ListAreaItem;
  program: IProgramListItem;
}

export interface IScheduleListResponse {
  schedule: {
    items: IScheduleListItem[];
  };
}

export interface IScheduleCreateValues {
  hour: number;
  minute: number;
  weekday: dayOfWeekCode;
  programCode: string;
  teacherCode: string;
  areaCode: string;
  paid: boolean;
  comment: string;
}

export interface IScheduleCreateArgs {
  hour: number;
  minute: number;
  weekday: dayOfWeekCode;
  paid: boolean;
  comment: string;

  teacher: ListTeacherItem;
  area: ListAreaItem;
  program: IProgramListItem;
}

export interface IScheduleEditArgs extends IScheduleCreateArgs {
  code: string;
}

export type IScheduleCreateRequest = IScheduleCreateValues;

export interface IScheduleCreateResponse {
  code: string;
}

export interface IScheduleDetail {
  hour: number;
  minute: number;
  weekday: dayOfWeekCode;
  programCode: string;
  teacherCode: string;
  areaCode: string;
  paid: boolean;
  comment: string;
}

export interface IScheduleDetailResponse {
  schedule: {
    code: string;
    time: string;
    hour: number;
    minute: number;
    weekday: dayOfWeekCode;
    paid: boolean;
    comment: string;

    teacher: ListTeacherItem;
    area: ListAreaItem;
    program: IProgramListItem;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScheduleEditRequest extends IScheduleCreateValues {}

export const convertHourAndMinutesToTime = (
  hours: number,
  minutes: number
): string => {
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

export const convertTimeToHour = (time: string): number => {
  const args = time.split(":");
  if (args.length !== 2 && !args[0] && !args[1])
    throw new Error("Error converting time");
  return Number(args[0]);
};

export const convertTimeToMinute = (time: string): number => {
  const args = time.split(":");
  if (args.length !== 2 && !args[0] && !args[1])
    throw new Error("Error converting time");
  return Number(args[1]);
};

export const convertScheduleCreateArgsToDetail = (
  args: IScheduleCreateArgs
): IScheduleDetail => {
  return {
    hour: args.hour,
    minute: args.minute,
    programCode: args.program.code,
    teacherCode: args.teacher.code,
    areaCode: args.area.code,
    weekday: args.weekday,
    paid: args.paid,
    comment: args.comment,
  };
};

export const convertScheduleCreateArgsToListItem = (
  code: string,
  args: IScheduleCreateArgs // | IScheduleDetailResponse["schedule"]
): IScheduleListItem => {
  return {
    code,
    time: convertHourAndMinutesToTime(args.hour, args.minute),
    weekday: args.weekday,
    paid: args.paid,
    comment: args.comment,
    teacher: args.teacher,
    area: args.area,
    program: args.program,
  };
};
