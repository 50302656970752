import { MenuOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Layout, Menu, Row } from "antd";
import "antd/dist/antd.min.css";
import { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import menuItems, { mobileMenuItems } from "@/constants/mainMenu";
import useLogout from "@/hooks/useLogout";
import frontendPaths from "@/routes/paths";

import Logo from "../components/Logo";

type LayoutTemplateProperties = {
  children: ReactNode;
};

const { Header, Content, Footer, Sider } = Layout;

function AdminLayoutTemplate({ children }: LayoutTemplateProperties) {
  const logout = useLogout();
  const location = useLocation();
  const locationSection = `/${location.pathname.split("/")[1]}`;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <Layout className="layout">
      <Header>
        <Row>
          <Col flex="1 0 auto" className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          <Col flex="1 0 auto" className="logout">
            <Button
              className="mMenuBtn"
              onClick={() => setDrawerIsOpen(true)}
              icon={<MenuOutlined />}
            />
            <Drawer
              title="Главное меню"
              placement="right"
              closable
              onClose={() => setDrawerIsOpen(false)}
              open={drawerIsOpen}
            >
              <Menu
                mode="inline"
                items={mobileMenuItems}
                selectedKeys={[locationSection]}
                onClick={() => setDrawerIsOpen(false)}
              />
            </Drawer>
            <Button className="logoutBtn" onClick={() => logout()}>
              {frontendPaths.logout.title}
            </Button>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            items={menuItems}
            selectedKeys={[locationSection]}
          />
        </Sider>
        <Content>
          <div className="site-layout-content">{children}</div>
        </Content>
      </Layout>
      <Footer className="site-footer">© Interso 2022</Footer>
    </Layout>
  );
}

export default AdminLayoutTemplate;
