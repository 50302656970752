import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import {
  IProductCreateRequest,
  IProductCreateResponse,
  IProductDetail,
  IProductDetailResponse,
  IProductEditRequest,
  IProductEditRequestArgs,
  IProductListItem,
  IProductListResponse,
  IProductSelectItem,
  IProductSelectListResponse,
} from "@/models/products";
import { FilterType, IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IProductsState extends IEntitiesState {
  entities?: IProductListItem[];
  productDetail: IProductDetail | null;
  allProducts?: IProductSelectItem[];
  filters: FilterType;
}

const initialState: IProductsState = {
  loading: "idle",
  error: null,
  success: null,
  productDetail: null,
  filters: {},
};

export type fetchProductsParams = {
  page: number;
  pageSize?: number;
  clubCodes?: string[];
};

export const fetchProducts = createAsyncThunk<
  IProductListResponse,
  fetchProductsParams,
  { rejectValue: IThunkCustomError }
>("products/fetchProducts", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PRODUCTS_URL(), {
      params: {
        page: params.page,
        pageSize: params.pageSize || defaultPageSize,
        clubCode: params.clubCodes,
      },
    });
    return response.data as IProductListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchAllProducts = createAsyncThunk<
  IProductSelectListResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("products/fetchAllProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PRODUCTS_ALL_URL());
    return response.data as IProductSelectListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchProduct = createAsyncThunk<
  IProductDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("products/fetchProduct", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PRODUCT_URL(coachCode));
    return response.data as IProductDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createProduct = createAsyncThunk<
  IProductCreateResponse,
  IProductCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "products/createProduct",
  async (request: IProductCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        backendPaths.PRODUCT_CREATE_URL(),
        JSON.stringify(request)
      );
      return response.data as IProductCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const editProduct = createAsyncThunk<
  null,
  IProductEditRequestArgs,
  { rejectValue: IThunkCustomError }
>(
  "products/editProduct",
  async (product: IProductEditRequestArgs, { rejectWithValue }) => {
    try {
      const productCode = product.code;
      const request: PartialBy<IProductEditRequestArgs, "code"> = {
        ...product,
      };
      delete request.code;
      const backendRequest: IProductEditRequest = {
        title: product.title,
        oldPrice: product.oldPrice,
        price: product.price,
        description: product.description,
        clubCodes: product.clubs.map((el) => el.code),
        active: product.active,
        promocode: product.promocode,
      };
      const response = await axios.post(
        backendPaths.PRODUCT_EDIT_URL(productCode),
        JSON.stringify(backendRequest)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteProduct = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>(
  "products/deleteProduct",
  async (productCode: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        backendPaths.PRODUCT_DELETE_URL(productCode)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

interface ISortParam {
  code: string;
  direction: "up" | "down";
}

export const sortProduct = createAsyncThunk<
  null,
  ISortParam,
  { rejectValue: IThunkCustomError }
>("products/sortProduct", async (param: ISortParam, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      backendPaths.PRODUCTS_SORT_URL(param.code, param.direction)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const productsAdapter = createEntityAdapter<IProductListItem>({
  selectId: (item) => item.code,
});

const productsSlice = createSlice({
  name: "products",
  initialState: productsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
    // setFilters: (state, action: PayloadAction<FilterType>) => {
    //   state.filters = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      // fetch products
      .addCase(fetchProducts.fulfilled, (state, action) => {
        productsAdapter.removeAll(state);
        productsAdapter.addMany(state, action.payload.products.items);
        state.pagination = action.payload.products.pagination;
      })
      // fetch one product
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product;
      })
      // fetch all products for select
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.allProducts = action.payload.products.items;
      })
      // create product
      .addCase(createProduct.fulfilled, (state) => {
        // const newProduct: IProductListItem = {
        //   code: action.payload.code,
        //   title: action.meta.arg.title,
        //   oldPrice: action.meta.arg.oldPrice,
        //   price: action.meta.arg.price,
        //   active: action.meta.arg.active,
        //   promocode: action.meta.arg.promocode,
        //   // clubs: action.meta.arg.clubCodes,
        // };
        // productsAdapter.addOne(state, newProduct);
        state.success = setSuccessMessage("Акция добавлена");
      })
      // edit product
      .addCase(editProduct.fulfilled, (state, action) => {
        const update = {
          id: action.meta.arg.code,
          changes: {
            title: action.meta.arg.title,
            description: action.meta.arg.description,
            price: action.meta.arg.price,
            active: action.meta.arg.active,
            clubs: action.meta.arg.clubs,
            promocode: action.meta.arg.promocode,
          },
        };
        productsAdapter.updateOne(state, update);
        const productDetail: IProductDetail = {
          code: action.meta.arg.code,
          active: action.meta.arg.active,
          title: action.meta.arg.title,
          oldPrice: action.meta.arg.oldPrice,
          price: action.meta.arg.price,
          clubs: action.meta.arg.clubs,
          promocode: action.meta.arg.promocode,
          description: action.meta.arg.description,
        };
        state.productDetail = productDetail;
        state.success = setSuccessMessage("Акция обновлена");
      })
      // delete product
      .addCase(deleteProduct.fulfilled, (state, action) => {
        productsAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Акция удалена", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("products", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("products", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = productsSlice.actions;
export const productsSelectors = productsAdapter.getSelectors<RootState>(
  (state: RootState) => state.products
);
export default productsSlice.reducer;
