import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import {
  IClubAddress,
  IClubAddressEditRequest,
  IClubDetail,
  IClubDetailResponse,
  IClubEditRequest,
  IClubListItem,
  IClubOptionsItem,
  IClubsAddressResponse,
  IClubsAreasResponse,
  IClubsEditPhotosArgs,
  IClubsEditPhotosRequest,
  IClubsOptionsResponse,
  IClubsPhotosResponse,
  IClubsResponse,
} from "@/models/clubs";
import { IThunkCustomError } from "@/models/error";
import { convertPhotoListItemoPhotoCodes } from "@/models/files";
import {
  IClubOptionCreateRequest,
  IClubOptionCreateResponse,
  IOptionEditRequest,
} from "@/models/options";
import { IPhotoListItem } from "@/models/photo";
import {
  IAllAreaItem,
  IAllAreasResponse,
  IAreaEditRequest,
  IClubAreaCreateRequest,
  IClubAreaCreateResponse,
  IClubAreasItem,
} from "@/models/rooms";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IClubsState extends IEntitiesState {
  entities?: IClubListItem[];
  clubDetail: IClubDetail | null;
  clubAddress: IClubAddress | null;
  clubAllAreas: IAllAreaItem[] | null;
  clubAreas: IClubAreasItem[] | null;
  clubOptions: IClubOptionsItem[] | null;
  clubPhotos?: IPhotoListItem[];
}

const initialState: IClubsState = {
  loading: "idle",
  error: null,
  success: null,
  clubDetail: null,
  clubAddress: null,
  clubAllAreas: null,
  clubAreas: null,
  clubOptions: null,
};

// list
export const fetchClubs = createAsyncThunk<
  IClubsResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClubs", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUBS_URL());
    return response.data as IClubsResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const clubsAdapter = createEntityAdapter<IClubListItem>({
  selectId: (item) => item.code,
});

// detail
export const fetchClub = createAsyncThunk<
  IClubDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClub", async (clubCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUB_URL(clubCode));
    return response.data as IClubDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchClubAreas = createAsyncThunk<
  IClubsAreasResponse,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClubAreas", async (clubCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUB_AREAS_URL(clubCode));
    return response.data as IClubsAreasResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchClubOptions = createAsyncThunk<
  IClubsOptionsResponse,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClubOptions", async (clubCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUB_OPTIONS_URL(clubCode));
    return response.data as IClubsOptionsResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchClubAddress = createAsyncThunk<
  IClubsAddressResponse,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClubAddress", async (clubCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUB_ADDRESS_URL(clubCode));
    return response.data as IClubsAddressResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchClubPhotos = createAsyncThunk<
  IClubsPhotosResponse,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/fetchClubPhotos", async (code, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.CLUB_PHOTOS_URL(code));
    return response.data as IClubsPhotosResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editClubPhotos = createAsyncThunk<
  null,
  IClubsEditPhotosArgs,
  { rejectValue: IThunkCustomError }
>("clubs/editClubPhotos", async (club, { rejectWithValue }) => {
  try {
    const clubCode = club.code;
    const request: IClubsEditPhotosRequest = {
      items: convertPhotoListItemoPhotoCodes(club.photos),
    };
    const response = await axios.post(
      backendPaths.CLUB_PHOTOS_EDIT_URL(clubCode),
      JSON.stringify(request)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editClub = createAsyncThunk<
  IClubDetail,
  IClubEditRequest,
  { rejectValue: IThunkCustomError }
>("clubs/editClub", async (club: IClubEditRequest, { rejectWithValue }) => {
  try {
    const clubCode = club.code;
    const request: PartialBy<IClubEditRequest, "code"> = {
      ...club,
    };
    delete request.code;
    const response = await axios.post(
      backendPaths.CLUB_EDIT_URL(clubCode),
      JSON.stringify(request)
    );
    return response.data as IClubDetail;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

// areas
export const fetchAllAreas = createAsyncThunk<
  IAllAreasResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("clubs/fetchAllAreas", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.AREAS_URL());
    return response.data as IAllAreasResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createClubArea = createAsyncThunk<
  IClubAreaCreateResponse,
  IClubAreaCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "clubs/createClubArea",
  async (request: IClubAreaCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        backendPaths.CLUB_CREATE_ROOM_URL(),
        JSON.stringify(request)
      );
      return response.data as IClubAreaCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteArea = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/deleteArea", async (areaCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(backendPaths.AREA_DELETE_URL(areaCode));
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editArea = createAsyncThunk<
  null,
  IAreaEditRequest,
  { rejectValue: IThunkCustomError }
>("clubs/editArea", async (area: IAreaEditRequest, { rejectWithValue }) => {
  try {
    const areaCode = area.code;
    const request: PartialBy<IAreaEditRequest, "code"> = {
      ...area,
    };
    delete request.code;
    const response = await axios.post(
      backendPaths.AREA_EDIT_URL(areaCode),
      JSON.stringify(request)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editAddress = createAsyncThunk<
  null,
  IClubAddressEditRequest,
  { rejectValue: IThunkCustomError }
>(
  "clubs/editAddress",
  async (clubAddress: IClubAddressEditRequest, { rejectWithValue }) => {
    try {
      const clubCode = clubAddress.code;
      const request: PartialBy<IClubAddressEditRequest, "code"> = {
        ...clubAddress,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.CLUB_ADDRESS_EDIT_URL(clubCode),
        JSON.stringify(request)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const createClubOption = createAsyncThunk<
  IClubOptionCreateResponse,
  IClubOptionCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "clubs/createClubOption",
  async (request: IClubOptionCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        backendPaths.CLUB_CREATE_OPTION_URL(),
        JSON.stringify(request)
      );
      return response.data as IClubOptionCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const editOption = createAsyncThunk<
  null,
  IOptionEditRequest,
  { rejectValue: IThunkCustomError }
>(
  "clubs/editOption",
  async (option: IOptionEditRequest, { rejectWithValue }) => {
    try {
      const optionCode = option.code;
      const request: PartialBy<IOptionEditRequest, "code"> = {
        ...option,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.OPTION_EDIT_URL(optionCode),
        JSON.stringify(request)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteOption = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("clubs/deleteOption", async (optionCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.OPTION_DELETE_URL(optionCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const clubsSlice = createSlice({
  name: "clubs",
  initialState: clubsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClubs.fulfilled, (state, action) => {
        clubsAdapter.addMany(state, action.payload.items);
      })
      // fetching club
      .addCase(fetchClub.fulfilled, (state, action) => {
        state.clubDetail = action.payload.detail;
      })
      // fetching areas (rooms)
      .addCase(fetchClubAreas.fulfilled, (state, action) => {
        state.clubAreas = action.payload.areas.items;
      })
      // fetching options
      .addCase(fetchClubOptions.fulfilled, (state, action) => {
        state.clubOptions = action.payload.options.items;
      })
      // fetching address
      .addCase(fetchClubAddress.fulfilled, (state, action) => {
        state.clubAddress = action.payload.address;
      })
      // fetching club photos
      .addCase(fetchClubPhotos.fulfilled, (state, action) => {
        state.clubPhotos = action.payload.photos.items;
      })
      // edit club photos
      .addCase(editClubPhotos.fulfilled, (state, action) => {
        state.clubPhotos = action.meta.arg.photos;
        state.success = setSuccessMessage("Фотографии обновлены");
      })
      // edit club
      .addCase(editClub.fulfilled, (state, action) => {
        const editedClub: PartialBy<IClubEditRequest, "code"> = action.meta.arg;
        delete editedClub.code;
        state.clubDetail = editedClub;
        state.success = setSuccessMessage("Информация о клубе обновлена");
      })
      // fetching all areas (rooms)
      .addCase(fetchAllAreas.fulfilled, (state, action) => {
        state.clubAllAreas = action.payload.areas.items;
      })
      // create area for club
      .addCase(createClubArea.fulfilled, (state, action) => {
        const createdArea: IClubAreasItem = {
          code: action.payload.code,
          name: action.meta.arg.name,
        };
        state.clubAreas?.push(createdArea);
        state.success = setSuccessMessage("Зал добавлен");
      })
      // delete room
      .addCase(deleteArea.fulfilled, (state, action) => {
        if (state.clubAreas) {
          const index = state.clubAreas.findIndex(
            (item) => item.code === action.meta.arg
          );
          state.clubAreas.splice(index, 1);
        }
        state.success = setSuccessMessage("Зал удален");
      })
      // edit room
      .addCase(editArea.fulfilled, (state, action) => {
        if (state.clubAreas) {
          const index = state.clubAreas.findIndex(
            (item) => item.code === action.meta.arg.code
          );
          state.clubAreas[index].name = action.meta.arg.name;
        }
        state.success = setSuccessMessage("Зал изменен");
      })
      // add option
      .addCase(createClubOption.fulfilled, (state, action) => {
        const newOption = {
          code: action.payload.code,
          icon: action.meta.arg.icon,
          name: action.meta.arg.name,
        };
        state.clubOptions?.push(newOption);
        state.success = setSuccessMessage("Опция добавлена к клубу");
      })
      // edit option
      .addCase(editOption.fulfilled, (state, action) => {
        if (state.clubOptions) {
          const index = state.clubOptions.findIndex(
            (item) => item.code === action.meta.arg.code
          );
          state.clubOptions[index].name = action.meta.arg.name;
          state.clubOptions[index].icon = action.meta.arg.icon;
        }
        state.success = setSuccessMessage("Опция изменена");
      })
      // delete option
      .addCase(deleteOption.fulfilled, (state, action) => {
        if (state.clubOptions) {
          const index = state.clubOptions.findIndex(
            (item) => item.code === action.meta.arg
          );
          state.clubOptions.splice(index, 1);
        }
        state.success = setSuccessMessage("Опция удалена");
      })
      // edit club address
      .addCase(editAddress.fulfilled, (state, action) => {
        const newAddress = {
          ...action.meta.arg,
        };
        state.clubAddress = newAddress;
        state.success = setSuccessMessage("Адрес изменен");
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("clubs", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("clubs", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = clubsSlice.actions;
export const clubsSelectors = clubsAdapter.getSelectors<RootState>(
  (state: RootState) => state.clubs
);
export default clubsSlice.reducer;
