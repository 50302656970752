import Authorization from "../pages/Authorization";
import ClubAddressEdit from "../pages/ClubAddressEdit";
import ClubEdit from "../pages/ClubEdit";
import ClubPage from "../pages/ClubPage";
import ClubPhotoEdit from "../pages/ClubPhotosEdit";
import Clubs from "../pages/Clubs";
import CoachCreate from "../pages/CoachCreate";
import CoachEdit from "../pages/CoachEdit";
import Coachs from "../pages/Coachs";
import Dashboard from "../pages/Dashboard";
import DocCreate from "../pages/DocCreate";
import DocEdit from "../pages/DocEdit";
import Docs from "../pages/Docs";
import FormQueries from "../pages/FormQueries";
import FormQueriesPage from "../pages/FormQueriesPage";
import Logout from "../pages/Logout";
import News from "../pages/News";
import NewsCreate from "../pages/NewsCreate";
import NewsEdit from "../pages/NewsEdit";
import OfferCreate from "../pages/OfferCreate";
import OfferEdit from "../pages/OfferEdit";
import Offers from "../pages/Offers";
import OrderPage from "../pages/OrderPage";
import Orders from "../pages/Orders";
import PageNotFound from "../pages/PageNotFound";
import ProductCreate from "../pages/ProductCreate";
import ProductEdit from "../pages/ProductEdit";
import Products from "../pages/Products";
import ProgrammCreate from "../pages/ProgrammCreate";
import ProgrammEdit from "../pages/ProgrammEdit";
import Programms from "../pages/Programms";
import PromocodeCreate from "../pages/PromocodeCreate";
import PromocodeEdit from "../pages/PromocodeEdit";
import Promocodes from "../pages/Promocodes";
import Schedule from "../pages/Schedule";
import ScheduleCreate from "../pages/ScheduleCreate";
import ScheduleEdit from "../pages/ScheduleEdit";
import SliderCreate from "../pages/SliderCreate";
import SliderEdit from "../pages/SliderEdit";
import Sliders from "../pages/Sliders";

interface IRoutes {
  [key: string]: {
    readonly URL: (id?: string) => string;
    readonly title: string;
    readonly element: JSX.Element;
    readonly public: boolean;
    readonly mainMenu: boolean;
    readonly children?: IRoutes;
  };
}

const frontendPaths: IRoutes = {
  login: {
    URL: () => "/admin",
    element: <Authorization />,
    title: "Авторизация",
    public: true,
    mainMenu: false,
  },
  logout: {
    URL: () => "/logout",
    element: <Logout />,
    title: "Выход",
    public: false,
    mainMenu: false,
  },
  page_not_found: {
    URL: () => "*",
    element: <PageNotFound />,
    title: "Страница не найдена",
    public: true,
    mainMenu: false,
  },
  main: {
    URL: () => "/",
    element: <Dashboard />,
    title: "Главная",
    public: false,
    mainMenu: true,
  },
  clubs: {
    URL: () => "/clubs",
    element: <Clubs />,
    title: "Клубы",
    public: false,
    mainMenu: true,
  },
  club: {
    URL: (id = ":id") => `/clubs/${id}`,
    element: <ClubPage />,
    title: "Клуб",
    public: false,
    mainMenu: false,
  },
  club_edit: {
    URL: (id = ":id") => `/clubs/edit/${id}`,
    element: <ClubEdit />,
    title: "Редактирование клуба",
    public: false,
    mainMenu: false,
  },
  club_address_edit: {
    URL: (id = ":id") => `/clubs/address/${id}/edit`,
    element: <ClubAddressEdit />,
    title: "Редактирование адреса клуба",
    public: false,
    mainMenu: false,
  },
  club_photos_edit: {
    URL: (id = ":id") => `/clubs/${id}/photos`,
    element: <ClubPhotoEdit />,
    title: "Редактирование фото клуба",
    public: false,
    mainMenu: false,
  },
  coachs: {
    URL: () => "/coachs",
    element: <Coachs />,
    title: "Тренеры",
    public: false,
    mainMenu: true,
  },
  coach_create: {
    URL: () => "/coachs/create",
    element: <CoachCreate />,
    title: "Добавление тренера",
    public: false,
    mainMenu: false,
  },
  coach_edit: {
    URL: (id = ":id") => `/coachs/${id}/edit`,
    element: <CoachEdit />,
    title: "Редактирование тренера",
    public: false,
    mainMenu: false,
  },
  programms: {
    URL: () => "/programms",
    element: <Programms />,
    title: "Программы",
    public: false,
    mainMenu: true,
  },
  programm_edit: {
    URL: (id = ":id") => `/programms/${id}/edit`,
    element: <ProgrammEdit />,
    title: "Редактирование программы",
    public: false,
    mainMenu: false,
  },
  programm_create: {
    URL: () => "/programms/create",
    element: <ProgrammCreate />,
    title: "Добавление программы",
    public: false,
    mainMenu: false,
  },
  schedule: {
    URL: () => "/schedule",
    element: <Schedule />,
    title: "Расписание",
    public: false,
    mainMenu: true,
  },
  schedule_create: {
    URL: () => "/schedule/create",
    element: <ScheduleCreate />,
    title: "Добавление события",
    public: false,
    mainMenu: false,
  },
  schedule_edit: {
    URL: (id = ":id") => `/schedule/${id}/edit`,
    element: <ScheduleEdit />,
    title: "Редактирование события",
    public: false,
    mainMenu: false,
  },
  news: {
    URL: () => "/news",
    element: <News />,
    title: "Новости",
    public: false,
    mainMenu: true,
  },
  news_create: {
    URL: () => "/news/create",
    element: <NewsCreate />,
    title: "Добавление новости",
    public: false,
    mainMenu: false,
  },
  news_edit: {
    URL: (id = ":id") => `/news/${id}/edit`,
    element: <NewsEdit />,
    title: "Редактирование новости",
    public: false,
    mainMenu: false,
  },
  docs: {
    URL: () => "/docs",
    element: <Docs />,
    title: "Документы",
    public: false,
    mainMenu: true,
  },
  doc_create: {
    URL: () => "/docs/create",
    element: <DocCreate />,
    title: "Добавление документа",
    public: false,
    mainMenu: false,
  },
  doc_edit: {
    URL: (id = ":id") => `/docs/${id}/edit`,
    element: <DocEdit />,
    title: "Редактирование документа",
    public: false,
    mainMenu: false,
  },
  products: {
    URL: () => "/products",
    element: <Products />,
    title: "Акции",
    public: false,
    mainMenu: true,
  },
  product_create: {
    URL: () => "/products/create",
    element: <ProductCreate />,
    title: "Добавление акции",
    public: false,
    mainMenu: false,
  },
  product_edit: {
    URL: (id = ":id") => `/products/${id}/edit`,
    element: <ProductEdit />,
    title: "Редактирование акции",
    public: false,
    mainMenu: false,
  },
  promocodes: {
    URL: () => "/promocodes",
    element: <Promocodes />,
    title: "Промокоды",
    public: false,
    mainMenu: true,
  },
  promocode_create: {
    URL: () => "/promocodes/create",
    element: <PromocodeCreate />,
    title: "Добавление промокода",
    public: false,
    mainMenu: false,
  },
  promocode_edit: {
    URL: (id = ":id") => `/promocodes/${id}/edit`,
    element: <PromocodeEdit />,
    title: "Редактирование промокода",
    public: false,
    mainMenu: false,
  },
  offers: {
    URL: () => "/offers",
    element: <Offers />,
    title: "Спец-предложения",
    public: false,
    mainMenu: true,
  },
  offer_create: {
    URL: () => "/offers/create",
    element: <OfferCreate />,
    title: "Добавление спец-предложения",
    public: false,
    mainMenu: false,
  },
  offer_edit: {
    URL: (id = ":id") => `/offers/${id}/edit`,
    element: <OfferEdit />,
    title: "Редактирование спец-предложения",
    public: false,
    mainMenu: false,
  },
  orders: {
    URL: () => `/orders`,
    element: <Orders />,
    title: "Заказы",
    public: false,
    mainMenu: true,
  },
  order: {
    URL: (id = ":id") => `/orders/${id}`,
    element: <OrderPage />,
    title: "Заказ",
    public: false,
    mainMenu: false,
  },
  form_queries: {
    URL: () => `/form-queries`,
    element: <FormQueries />,
    title: "Обратная связь",
    public: false,
    mainMenu: true,
  },
  form_querie: {
    URL: (id = ":id") => `/form-queries/${id}`,
    element: <FormQueriesPage />,
    title: "Обратная связь",
    public: false,
    mainMenu: false,
  },
  sliders: {
    URL: () => `/sliders/`,
    element: <Sliders />,
    title: "Слайдеры",
    public: false,
    mainMenu: true,
  },
  slider_create: {
    URL: () => "/sliders/create",
    element: <SliderCreate />,
    title: "Добавление слайдера",
    public: false,
    mainMenu: false,
  },
  slider_edit: {
    URL: (id = ":id") => `/sliders/${id}/edit`,
    element: <SliderEdit />,
    title: "Редактирование слайдера",
    public: false,
    mainMenu: false,
  },
};

export default frontendPaths;
