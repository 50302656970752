import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import {
  IDocCreateRequest,
  IDocCreateResponse,
  IDocDetail,
  IDocDetailResponse,
  IDocEditRequest,
  IDocsListItem,
  IDocsListResponse,
} from "@/models/docs";
import { IThunkCustomError } from "@/models/error";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IDocsState extends IEntitiesState {
  entities?: IDocsListItem[];
  docDetail: IDocDetail | null;
}

const initialState: IDocsState = {
  loading: "idle",
  error: null,
  success: null,
  docDetail: null,
};

export const fetchDocs = createAsyncThunk<
  IDocsListResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("docs/fetchDocs", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.DOCS_URL());
    return response.data as IDocsListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchDoc = createAsyncThunk<
  IDocDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("docs/fetchDoc", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.DOC_URL(coachCode));
    return response.data as IDocDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createDoc = createAsyncThunk<
  IDocCreateResponse,
  IDocCreateRequest,
  { rejectValue: IThunkCustomError }
>("docs/createDoc", async (request: IDocCreateRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      backendPaths.DOC_CREATE_URL(),
      JSON.stringify(request)
    );
    return response.data as IDocCreateResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editDoc = createAsyncThunk<
  null,
  IDocEditRequest,
  { rejectValue: IThunkCustomError }
>("docs/editDoc", async (doc: IDocEditRequest, { rejectWithValue }) => {
  try {
    const docCode = doc.code;
    const request: PartialBy<IDocEditRequest, "code"> = {
      ...doc,
    };
    delete request.code;
    const response = await axios.post(
      backendPaths.DOC_EDIT_URL(docCode),
      JSON.stringify(request)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const deleteDoc = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("docs/deleteDoc", async (coachCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(backendPaths.DOC_DELETE_URL(coachCode));
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const docsAdapter = createEntityAdapter<IDocsListItem>({
  selectId: (item) => item.code,
});

const docsSlice = createSlice({
  name: "docs",
  initialState: docsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch docs
      .addCase(fetchDocs.fulfilled, (state, action) => {
        docsAdapter.addMany(state, action.payload.documents.items);
      })
      // fetch one doc
      .addCase(fetchDoc.fulfilled, (state, action) => {
        state.docDetail = action.payload.document;
        docsAdapter.addOne(state, action.payload.document);
      })
      // create doc
      .addCase(createDoc.fulfilled, (state, action) => {
        const newDoc: IDocsListItem = {
          code: action.payload.code,
          name: action.meta.arg.name,
          slug: action.meta.arg.slug,
          color: action.meta.arg.color,
          mainPageShow: action.meta.arg.mainPageShow,
        };
        docsAdapter.addOne(state, newDoc);
        state.success = setSuccessMessage("Документ добавлен!");
      })
      // edit doc
      .addCase(editDoc.fulfilled, (state, action) => {
        state.docDetail = action.meta.arg;
        const update = {
          id: action.meta.arg.code,
          changes: {
            name: action.meta.arg.name,
            description: action.meta.arg.description,
            color: action.meta.arg.color,
            mainPageShow: action.meta.arg.mainPageShow,
          },
        };
        docsAdapter.updateOne(state, update);
        state.success = setSuccessMessage("Документ обновлен");
      })
      // delete programm
      .addCase(deleteDoc.fulfilled, (state, action) => {
        docsAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Документ удален", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("docs", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("docs", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = docsSlice.actions;
export const docsSelectors = docsAdapter.getSelectors<RootState>(
  (state: RootState) => state.docs
);
export default docsSlice.reducer;
