import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import dayOfWeek from "@/constants/daysOfWeek";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { ICreatePageState } from "@/models/pages";
import { IScheduleCreateArgs, IScheduleCreateValues } from "@/models/schedule";
import frontendPaths from "@/routes/paths";
import { fetchAllAreas } from "@/slices/clubsSlice";
import { coachsSelectors, fetchCoachs } from "@/slices/coachsSlice";
import { fetchProgramms, programmsSelectors } from "@/slices/programmsSlice";
import { createEvent, resetErrors } from "@/slices/scheduleSlice";
import getFullName from "@/utils/getFullName";
import setMessages from "@/utils/setMessages";

function ScheduleCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { TextArea } = Input;

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchProgramms());
    void dispatch(fetchCoachs());
    void dispatch(fetchAllAreas());
  }, []);

  const scheduleData = useAppSelector(({ schedule }) => schedule);
  const { loading, error, success } = scheduleData;

  const programms = useAppSelector(programmsSelectors.selectAll);
  const programmsOptions = programms.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  const coachs = useAppSelector(coachsSelectors.selectAll);
  const coachsOptions = coachs.map((item) => {
    return {
      value: item.code,
      label: getFullName(item.firstName, item.lastName),
    };
  });

  const clubsData = useAppSelector(({ clubs }) => clubs);
  const areas = clubsData.clubAllAreas;
  const areasOptions = areas?.map((item) => {
    return {
      value: item.code,
      label: `${item.name} (${item.club.name})`,
    };
  });

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([scheduleData]);

  const handleScheduleCreate = (values: IScheduleCreateValues) => {
    const teacher = coachs.find((item) => item.code === values.teacherCode);
    const area = areas?.find((item) => item.code === values.areaCode);
    const program = programms.find((item) => item.code === values.programCode);
    if (teacher && area && program) {
      const request: IScheduleCreateArgs = {
        hour: values.hour,
        minute: values.minute,
        weekday: values.weekday,
        paid: values.paid,
        comment: values.comment,
        teacher,
        area,
        program,
      };
      void dispatch(createEvent(request));
    }
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.schedule_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createEvent"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleScheduleCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Время начала часы: минуты"
            style={{ marginBottom: "0" }}
          >
            <Input.Group>
              <Space>
                <Form.Item
                  name="hour"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста укажите час",
                    },
                  ]}
                >
                  <InputNumber min={0} max={23} />
                </Form.Item>
                <Form.Item
                  name="minute"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста укажите минуты",
                    },
                  ]}
                >
                  <InputNumber min={0} max={59} />
                </Form.Item>
              </Space>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label="День недели"
            name="weekday"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название программы",
              },
            ]}
          >
            <Select options={dayOfWeek} />
          </Form.Item>

          <Form.Item
            label="Программа"
            name="programCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите программу",
              },
            ]}
          >
            <Select options={programmsOptions} />
          </Form.Item>

          <Form.Item
            label="Тренер"
            name="teacherCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите количество калорий",
              },
            ]}
          >
            <Select options={coachsOptions} />
          </Form.Item>

          <Form.Item
            label="Зал и клуб"
            name="areaCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите зал и клуб",
              },
            ]}
          >
            <Select options={areasOptions} />
          </Form.Item>

          <Form.Item
            label="Платное участие"
            name="paid"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item label="Комментарии" name="comment">
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ScheduleCreate;
