import { Button, Card, PageHeader, Pagination, Switch, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import ShowMessages from "@/components/ShowMessages";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import IPageState from "@/models/pages";
import { ISliderListItem } from "@/models/sliders";
import frontendPaths from "@/routes/paths";
import {
  deleteSlider,
  fetchSliders,
  resetErrors,
  slidersSelectors,
  toggleSlider,
} from "@/slices/slidersSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function Sliders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);
  const [isDocDeleteMode, setDocDeleteMode] = useState(false);
  const [removableDoc, setRemovableDoc] = useState<ISliderListItem | null>(
    null
  );

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchSliders(1));
  }, []);

  const slidersData = useAppSelector(({ sliders }) => sliders);
  const { loading, success, error, pagination } = slidersData;

  const docs = addReactKey<ISliderListItem>(
    useAppSelector(slidersSelectors.selectAll)
  );

  const messages = setMessages([slidersData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && docs) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error, docs]);

  // delete document
  const handleTurnOnDocDeleteMode = (code: string) => {
    const docToRemove = docs.find((item) => item.code === code);
    if (docToRemove) {
      setDocDeleteMode(true);
      setRemovableDoc(docToRemove);
    }
  };

  const handleDeleteDocSubmit = (code: string) => {
    void dispatch(deleteSlider(code));
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const handleDeleteDocCancel = () => {
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const handleTogleSlider = (slider: ISliderListItem) => {
    void dispatch(toggleSlider(slider));
  };

  const onPageChange = (page: number) => {
    void dispatch(fetchSliders(page));
  };

  const columns: ColumnProps<ISliderListItem>[] = [
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      width: "100%",
    },
    {
      title: "Активен",
      dataIndex: "active",
      key: "active",
      width: "100",
      align: "center",
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.active}
          onChange={() => handleTogleSlider(record)}
        />
      ),
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.slider_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnDocDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Слайдеры"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.slider_create.URL()}>
            <Button type="primary">{frontendPaths.slider_create.title}</Button>
          </Link>
        }
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={docs}
          columns={columns}
          pagination={false}
          loading={isPageState === IPageState.loading}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      {removableDoc && (
        <DeleteConfirmModal
          isOpen={isDocDeleteMode}
          onOk={() => handleDeleteDocSubmit(removableDoc.code)}
          onCancel={handleDeleteDocCancel}
          text={`Удалить слайдер: ${removableDoc.title}?`}
        />
      )}
    </>
  );
}

export default Sliders;
