import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import {
  convertIFileResponseToPhotoListItem,
  createInitUploadConfig,
} from "@/models/files";
import { INewsEditArgs, INewsEditValues } from "@/models/news";
import { IEditPageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { editNews, fetchNewsDetail } from "@/slices/newsSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

function NewsEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && dispatch(fetchNewsDetail(param.id));
  }, []);

  const newsDetailData = useAppSelector(({ news }) => news);
  const { newsDetail, loading, error, success } = newsDetailData;

  const initFile = createInitUploadConfig(newsDetail?.photo);

  console.log("init file: ", initFile);

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && newsDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [newsDetail, loading, error, success]);

  const messages = setMessages([newsDetailData]);

  const handleNewsEdit = (values: INewsEditValues) => {
    console.log("values: ", values);

    if (!newsDetail || !values.photo || !values.photo[0]?.response?.code)
      return;

    const request: INewsEditArgs = {
      code: newsDetail.code,
      date: dayjs(values.date).format("YYYY-MM-DD hh:mm:ss"),
      title: values.title,
      content: values.content,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(editNews(request));
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return newsDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.news_edit.title}: ${newsDetail.title}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        {newsDetail ? (
          <Form
            name="editNews"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              title: newsDetail.title,
              date: dayjs(newsDetail.date, "YYYY-MM-DD hh:mm:ss"),
              content: newsDetail.content,
              photo: initFile,
            }}
            onFinish={handleNewsEdit}
          >
            <Form.Item
              label="Заголовок новости"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок новости",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Дата и время"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите дату и время новости",
                },
              ]}
            >
              <DatePicker showTime />
            </Form.Item>

            <Form.Item
              label="Текст новости"
              name="content"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите текст новости",
                },
              ]}
            >
              <TextEditor value="content" placeholder="Текст новости" />
            </Form.Item>

            <Form.Item
              label="Фото новости"
              name="photo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите фото новости",
                },
              ]}
            >
              <Upload
                accept="image/*"
                customRequest={customUpload}
                listType="picture-card"
                multiple={false}
                maxCount={1}
                defaultFileList={initFile}
              >
                <Button type="link">Загрузить</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Card>
    </>
  ) : null;
}

export default NewsEdit;
