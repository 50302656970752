import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import {
  INewsCreateArgs,
  INewsCreateRequest,
  INewsCreateResponse,
  INewsDetail,
  INewsDetailResponse,
  INewsEditArgs,
  INewsEditRequest,
  INewsListItem,
  INewsListResponse,
} from "@/models/news";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface INewsState extends IEntitiesState {
  entities?: INewsListItem[];
  newsDetail: INewsDetail | null;
}

const initialState: INewsState = {
  loading: "idle",
  error: null,
  success: null,
  newsDetail: null,
};

// working with list of entities
export const fetchNews = createAsyncThunk<
  INewsListResponse,
  number,
  { rejectValue: IThunkCustomError }
>("news/fetchNews", async (page, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.NEWS_URL(), {
      params: { page, pageSize: defaultPageSize },
    });
    return response.data as INewsListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const deleteNews = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("news/deleteNews", async (coachCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.NEWS_DELETE_URL(coachCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

// work with detaul entitie
export const createNews = createAsyncThunk<
  INewsCreateResponse,
  INewsCreateArgs,
  { rejectValue: IThunkCustomError }
>("news/createNews", async (args: INewsCreateArgs, { rejectWithValue }) => {
  try {
    const request: INewsCreateRequest = {
      title: args.title,
      date: args.date,
      content: args.content,
      photoCode: args.photo.code,
    };
    const response = await axios.post(
      backendPaths.NEWS_CREATE_URL(),
      JSON.stringify(request)
    );
    return response.data as INewsCreateResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchNewsDetail = createAsyncThunk<
  INewsDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("news/fetchNewsDetail", async (newsCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.NEWS_DETAIL_URL(newsCode));
    return response.data as INewsDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editNews = createAsyncThunk<
  null,
  INewsEditArgs,
  { rejectValue: IThunkCustomError }
>("news/editNews", async (args: INewsEditArgs, { rejectWithValue }) => {
  try {
    const { code } = args;
    const request: PartialBy<INewsEditArgs, "code"> = {
      ...args,
    };
    delete request.code;
    const backendRequest: INewsEditRequest = {
      title: args.title,
      date: args.date,
      content: args.content,
      photoCode: args.photo.code,
    };
    const response = await axios.post(
      backendPaths.NEWS_EDIT_URL(code),
      JSON.stringify(backendRequest)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const newsAdapter = createEntityAdapter<INewsListItem>({
  selectId: (item) => item.code,
});

const newsSlice = createSlice({
  name: "news",
  initialState: newsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch news
      .addCase(fetchNews.fulfilled, (state, action) => {
        newsAdapter.removeAll(state);
        newsAdapter.addMany(state, action.payload.news.items);
        state.pagination = action.payload.news.pagination;
      })
      // delete news
      .addCase(deleteNews.fulfilled, (state, action) => {
        newsAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Новость удалена");
      })
      // create news
      .addCase(createNews.fulfilled, (state, action) => {
        const newNews: INewsDetail = {
          code: action.payload.code,
          ...action.meta.arg,
        };
        state.newsDetail = newNews;
        state.success = setSuccessMessage("Новость добавлена");
      })
      // fetch one news
      .addCase(fetchNewsDetail.fulfilled, (state, action) => {
        state.newsDetail = action.payload.news;
      })
      // edit news
      .addCase(editNews.fulfilled, (state, action) => {
        state.newsDetail = action.meta.arg;
        state.success = setSuccessMessage("Новость обновлена");
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("news", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("news", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = newsSlice.actions;
export const newsSelectors = newsAdapter.getSelectors<RootState>(
  (state: RootState) => state.news
);
export default newsSlice.reducer;
