import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { ICreatePageState } from "@/models/pages";
import { IProductEditValues } from "@/models/products";
import frontendPaths from "@/routes/paths";
import { clubsSelectors, resetErrors } from "@/slices/clubsSlice";
import { createProduct } from "@/slices/productsSlice";
import setMessages from "@/utils/setMessages";

const { TextArea } = Input;

function ProductCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const productData = useAppSelector(({ products }) => products);
  const { loading, error, success } = productData;

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([productData]);

  const handleProductCreate = (values: IProductEditValues) => {
    void dispatch(createProduct(values));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.product_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProductCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Активна"
            name="active"
            valuePropName="checked"
            initialValue
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Заголовок акции"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок акции",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Старая цена" name="oldPrice">
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Цена"
            name="price"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цену",
              },
            ]}
          >
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Разрешить промокоды"
            name="promocode"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Клубы"
            name="clubCodes"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите клубы",
              },
            ]}
          >
            <Select options={clubsOptions} mode="multiple" />
          </Form.Item>

          <Form.Item
            label="Описание акции"
            name="description"
            tooltip="Переводы строк преобразуются в галочки"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание акции",
              },
            ]}
          >
            <TextArea rows={4} showCount maxLength={150} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ProductCreate;
