import {
  Button,
  Card,
  Form,
  Input,
  PageHeader,
  Radio,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { phoneRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import {
  coachTypes,
  ICoachCreateArgs,
  ICoachsCreateValues,
} from "@/models/coachs";
import { convertIFileResponseToPhotoListItem } from "@/models/files";
import { ICreatePageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { clubsSelectors, resetErrors } from "@/slices/clubsSlice";
import { createCoach } from "@/slices/coachsSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

const { TextArea } = Input;

function CoachCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const coachsData = useAppSelector(({ coachs }) => coachs);
  const { loading, error, success } = coachsData;

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  useEffect(() => {
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
  }, [loading, error, success]);

  const messages = setMessages([coachsData]);

  const handleCreateCoach = (values: ICoachsCreateValues) => {
    console.log("values: ", values);

    if (!values.photo || !values.photo[0].response?.code) return;

    const request: ICoachCreateArgs = {
      ...values,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
      clubs: clubs
        .filter((item) => values.clubCodes?.includes(item.code))
        .map((item) => {
          return {
            code: item.code,
            name: item.name,
            contactPhone: item.contactPhone,
          };
        }),
    };
    void dispatch(createCoach(request));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.coach_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createCoach"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleCreateCoach}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Имя тренера"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите имя тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Фамилия тренера"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фамилию тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип тренера"
            name="jobType"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип работы тренера",
              },
            ]}
          >
            <Radio.Group options={coachTypes} />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите телефон тренера",
              },
              {
                required: true,
                pattern: new RegExp(phoneRegExp),
                message: "Пожалуйста введите корректный телефон",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя телеграмм" name="socialTG">
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя вконтакте" name="socialVK">
            <Input />
          </Form.Item>

          <Form.Item label="Клубы тренера" name="clubCodes">
            <Select options={clubsOptions} mode="multiple" allowClear />
          </Form.Item>

          <Form.Item
            label="Краткое описание"
            name="shortBio"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите краткое описание тренера",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание тренера",
              },
            ]}
          >
            <TextEditor
              value="description"
              readonly={isCreatePageState === ICreatePageState.success}
            />
          </Form.Item>

          <Form.Item
            label="Фото тренера"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото тренера",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default CoachCreate;
