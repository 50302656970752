import { Button, Card, Form, Input, InputNumber, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IEditPageState } from "@/models/pages";
import { IProgrammEditRequest, IProgrammEditValues } from "@/models/programms";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import {
  editProgramm,
  fetchProgramm,
  programmsSelectors,
} from "@/slices/programmsSlice";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ProgrammEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    void dispatch(resetErrors());
    param.id && void dispatch(fetchProgramm(param.id));
  }, []);

  const programmsData = useAppSelector(({ programms }) => programms);
  const { loading, error, success } = programmsData;

  const programm = useAppSelector((state) =>
    param.id ? programmsSelectors.selectById(state, param.id) : undefined
  );

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && programm) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [programm, loading, error, success]);

  const messages = setMessages([programmsData]);

  const handleProgrammEdit = (values: IProgrammEditValues) => {
    console.log("values: ", values);
    if (programm) {
      const request: IProgrammEditRequest = {
        code: programm.code,
        ...values,
      };
      void dispatch(editProgramm(request));
    }
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return programm ? (
    <>
      <PageHeader
        title={`${frontendPaths.programm_edit.title}: ${programm.name}`}
        onBack={() => navigate(-1)}
      />
      {programm && (
        <Card>
          {(isEditPageState === IEditPageState.error ||
            isEditPageState === IEditPageState.success) && (
            <ShowMessages messages={messages} />
          )}
          <Form
            name="editCoach"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              name: programm.name,
              description: programm.description,
              duration: programm.duration,
              calories: programm.calories,
            }}
            onFinish={handleProgrammEdit}
          >
            <Form.Item
              label="Название программы"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите название программы",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Продолжительность, мин"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите продолжительность программы",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Калорий"
              name="calories"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите количество калорий",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Краткое описание"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите краткое описание",
                },
              ]}
            >
              <TextArea rows={4} showCount maxLength={100} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  ) : null;
}

export default ProgrammEdit;
