import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import {
  IOrderDetail,
  IOrderDetailResponse,
  IOrdersListItem,
  IOrdersResponse,
} from "@/models/orders";
import { IEntitiesState, IPaginationParams } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IOrdersState extends IEntitiesState {
  entities?: IOrdersListItem[];
  orderDetail: IOrderDetail | null;
}

const initialState: IOrdersState = {
  loading: "idle",
  error: null,
  success: null,
  orderDetail: null,
};

export const fetchOrders = createAsyncThunk<
  IOrdersResponse,
  IPaginationParams,
  { rejectValue: IThunkCustomError }
>("orders/fetchOrders", async (pagination, { rejectWithValue }) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: backendPaths.ORDERS_URL(),
    params: {
      page: pagination.page,
      pageSize: pagination.pageSize || defaultPageSize,
    },
  };
  try {
    const response = await axios(options);
    return response.data as IOrdersResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchOrder = createAsyncThunk<
  IOrderDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("orders/fetchOrder", async (orderCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.ORDER_URL(orderCode));
    return response.data as IOrderDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const ordersAdapter = createEntityAdapter<IOrdersListItem>({
  selectId: (item) => item.code,
});

const ordersSlice = createSlice({
  name: "orders",
  initialState: ordersAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch orders
      .addCase(fetchOrders.fulfilled, (state, action) => {
        ordersAdapter.removeAll(state);
        ordersAdapter.addMany(state, action.payload.customOrders.items);
        state.pagination = action.payload.customOrders.pagination;
      })
      // fetch order
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.orderDetail = action.payload.customOrder;
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("orders", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("orders", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = ordersSlice.actions;
export const ordersSelectors = ordersAdapter.getSelectors<RootState>(
  (state: RootState) => state.orders
);
export default ordersSlice.reducer;
