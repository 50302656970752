import { Card, PageHeader, Pagination, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IFormQueriesListItem } from "@/models/formQueries";
import IPageState from "@/models/pages";
import frontendPaths from "@/routes/paths";
import {
  fetchformQueries,
  formQueriesSelectors,
} from "@/slices/formQueriesSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function FormQueries() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);

  useEffect(() => {
    void dispatch(fetchformQueries({ page: 1 }));
  }, []);

  const formQueriesData = useAppSelector(({ formQueries }) => formQueries);
  const { loading, success, error, pagination } = formQueriesData;
  const orders = addReactKey<IFormQueriesListItem>(
    useAppSelector(formQueriesSelectors.selectAll)
  );

  const messages = setMessages([formQueriesData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && orders) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error]);

  const onPageChange = (page: number) => {
    void dispatch(fetchformQueries({ page }));
  };

  const columns: ColumnProps<IFormQueriesListItem>[] = [
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <Link to={frontendPaths.form_querie.URL(record.code)}>
          <TooltipDate date={record.createdAt} />
        </Link>
      ),
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (_, record) => `${record.name}`,
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => <Tag>{record.type}</Tag>,
    },
    {
      title: "club",
      dataIndex: "club",
      key: "club",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.club?.name,
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.form_queries.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={orders}
          columns={columns}
          loading={isPageState === IPageState.loading}
          pagination={false}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
    </>
  );
}

export default FormQueries;
