import { AnyAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { IStateSucessStatus, IThunkCustomError } from "@/models/error";

export const setSuccessMessage = (
  msg: string,
  fullScreen?: boolean
): IStateSucessStatus => {
  return {
    message: msg,
    fullScreen,
    type: "success",
  };
};

// keep in mind that TypeScript cannot use arrowFunctions for assertions.
export function assertAxiosError(err: unknown): asserts err is AxiosError {
  if (err instanceof AxiosError) return;
  console.error("assert error: ", err);
  throw err;
}

export function isThunkActionError(action: AnyAction): boolean {
  return action.type.endsWith("rejected");
}

export function isThunkActionPending(
  sliceName: string,
  action: AnyAction
): boolean {
  return action.type.startsWith(sliceName) && action.type.endsWith("pending");
}

export function isThunkActionFullfield(
  sliceName: string,
  action: AnyAction
): boolean {
  return action.type.startsWith(sliceName) && action.type.endsWith("fulfilled");
}

export const convertErrToCustomError = (
  err: AxiosError<any, any>
): IThunkCustomError => {
  const newError: IThunkCustomError = {
    name: err.name,
    message: err.response?.data?.message as string, // eslint-disable-line
    code: err.response?.data?.code as string, // eslint-disable-line
    details: err.response?.data?.details as IThunkCustomError["details"], // eslint-disable-line
    type: "error",
  };
  return newError;
};
