import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import locale from "antd/lib/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { convertIFileResponseToPhotoListItem } from "@/models/files";
import { INewsCreateValues } from "@/models/news";
import { ICreatePageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { createNews } from "@/slices/newsSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

function NewsCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    void dispatch(resetErrors());
  }, []);

  const newsData = useAppSelector(({ news }) => news);
  const { loading, error, success } = newsData;

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([newsData]);

  const handleNewsCreate = (values: INewsCreateValues) => {
    console.log("values: ", values);
    if (!values.photo || !values.photo[0]?.response?.code) return;

    const request = {
      title: values.title,
      date: dayjs(values.date).format("YYYY-MM-DD hh:mm:ss"),
      content: values.content,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(createNews(request));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.news_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleNewsCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Заголовок новости"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок новости",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дата и время"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату и время новости",
              },
            ]}
          >
            <DatePicker showTime locale={locale} />
          </Form.Item>

          <Form.Item
            label="Текст новости"
            name="content"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите текст новости",
              },
            ]}
          >
            <TextEditor
              value="content"
              readonly={isCreatePageState === ICreatePageState.success}
            />
          </Form.Item>

          <Form.Item
            label="Фото"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото тренера",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default NewsCreate;
