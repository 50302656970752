const BACKEND_FILES_URL = process.env.REACT_APP_BACKEND_FILES_URL || ""; // иначе ts считает что  | underfined

const backendPaths = {
  LOGIN_URL: () => "/auth/login",
  REFRESH_TOKEN_URL: () => "/auth/refresh",
  LOGOUT_URL: () => "/auth/logout",

  BACKEND_FILES_URL: (url: string) => `${BACKEND_FILES_URL}${url}`,

  FILES_UPLOAD_URL: () => "/files",
  FILE_FULL_URL: (url: string) => `${BACKEND_FILES_URL}/${url}`,

  OPTION_ICONS_URL: () => `/option-icons`,
  STREET_TYPES_URL: () => `/address-types`,

  AREAS_URL: () => `/areas`,
  AREA_DELETE_URL: (code: string) => `/areas/${code}`,
  AREA_EDIT_URL: (code: string) => `/areas/${code}`,

  OPTION_EDIT_URL: (code: string) => `/options/${code}`,
  OPTION_DELETE_URL: (code: string) => `/options/${code}`,

  CLUBS_URL: () => "/clubs",
  CLUB_URL: (code: string) => `/clubs/${code}/detail`,
  CLUB_ADDRESS_URL: (code: string) => `/clubs/${code}/address`,
  CLUB_ADDRESS_EDIT_URL: (code: string) => `/clubs/${code}/address`,
  CLUB_AREAS_URL: (code: string) => `/clubs/${code}/areas`,
  CLUB_OPTIONS_URL: (code: string) => `/clubs/${code}/options`,
  CLUB_EDIT_URL: (code: string) => `/clubs/${code}/detail`,
  CLUB_CREATE_ROOM_URL: () => `/areas`,
  CLUB_CREATE_OPTION_URL: () => `/options`,
  CLUB_PHOTOS_URL: (code: string) => `/clubs/${code}/photos`,
  CLUB_PHOTOS_EDIT_URL: (code: string) => `/clubs/${code}/photos`,

  COACHS_URL: () => `/teachers`,
  COACH_CREATE_URL: () => `/teachers`,
  COACH_URL: (code: string) => `/teachers/${code}`,
  COACH_EDIT_URL: (code: string) => `/teachers/${code}`,
  COACH_DELETE_URL: (code: string) => `/teachers/${code}`,

  PROGRAMMS_URL: () => `/programs`,
  PROGRAMM_URL: (code: string) => `/programs/${code}`,
  PROGRAMM_EDIT_URL: (code: string) => `/programs/${code}`,
  PROGRAMM_DELETE_URL: (code: string) => `/programs/${code}`,
  PROGRAMM_CREATE_URL: () => `/programs`,

  SCHEDULE_LIST_URL: () => `/schedule`,
  SCHEDULE_CREATE_URL: () => `/schedule`,
  SCHEDULE_URL: (code: string) => `/schedule/${code}`,
  SCHEDULE_EDIT_URL: (code: string) => `/schedule/${code}`,
  SCHEDULE_DELETE_URL: (code: string) => `/schedule/${code}`,

  DOCS_URL: () => `/documents`,
  DOC_URL: (code: string) => `/documents/${code}`,
  DOC_EDIT_URL: (code: string) => `/documents/${code}`,
  DOC_DELETE_URL: (code: string) => `/documents/${code}`,
  DOC_CREATE_URL: () => `/documents`,

  NEWS_URL: () => `/news`,
  NEWS_CREATE_URL: () => `/news`,
  NEWS_DELETE_URL: (code: string) => `/news/${code}`,
  NEWS_DETAIL_URL: (code: string) => `/news/${code}`,
  NEWS_EDIT_URL: (code: string) => `/news/${code}`,

  PRODUCTS_URL: () => `/products`,
  PRODUCT_CREATE_URL: () => `/products`,
  PRODUCT_URL: (code: string) => `/products/${code}`,
  PRODUCT_EDIT_URL: (code: string) => `/products/${code}`,
  PRODUCT_DELETE_URL: (code: string) => `/products/${code}`,
  PRODUCTS_ALL_URL: () => `/products/for-select`,
  PRODUCTS_SORT_URL: (code: string, direction: "up" | "down") =>
    `/products/${code}/${direction}`,

  PROMOCODES_URL: () => `/promocodes`,
  PROMOCODE_CREATE_URL: () => `/promocodes`,
  PROMOCODE_URL: (code: string) => `/promocodes/${code}`,
  PROMOCODE_EDIT_URL: (code: string) => `/promocodes/${code}`,
  PROMOCODE_DELETE_URL: (code: string) => `/promocodes/${code}`,

  OFFERS_URL: () => `/special-offer`,
  OFFER_CREATE_URL: () => `/special-offer`,
  OFFER_URL: (code: string) => `/special-offer/${code}`,
  OFFER_EDIT_URL: (code: string) => `/special-offer/${code}`,
  OFFER_DELETE_URL: (code: string) => `/special-offer/${code}`,

  ORDERS_URL: () => `/custom-orders`,
  ORDER_URL: (code: string) => `/custom-orders/${code}`,

  FORM_QUERIES_URL: () => `/client-queries`,
  FORM_QUERIE_URL: (code: string) => `/client-queries/${code}`,

  SLIDERS_URL: () => `/sliders`,
  SLIDER_URL: (code: string) => `/sliders/${code}`,
  SLIDER_EDIT_URL: (code: string) => `/sliders/${code}`,
  SLIDER_DELETE_URL: (code: string) => `/sliders/${code}`,
  SLIDER_CREATE_URL: () => `/sliders`,
  SLIDER_TOGGLE_URL: (code: string) => `/sliders/${code}/active`,
};

export default backendPaths;
