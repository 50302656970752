import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAppSelector } from "@/hooks/useReduxStoreHooks";

import frontendPaths from "./paths";

function RequireAuth() {
  const { accessToken } = useAppSelector(({ auth }) => auth);
  const location = useLocation();

  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={frontendPaths.login.URL()}
      state={{ from: location }}
      replace
    />
  );
}

export default RequireAuth;
