import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { IStreetTypeItem, IStreetTypesResponse } from "@/models/address";
import { IThunkCustomError } from "@/models/error";
import { IEntitiesState } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IOptionIconsState extends IEntitiesState {
  entities?: IStreetTypeItem[];
}

const initialState: IOptionIconsState = {
  loading: "idle",
  error: null,
  success: null,
};

export const fetchstreetTypes = createAsyncThunk<
  IStreetTypesResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("streetTypes/fetchstreetTypes", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.STREET_TYPES_URL());
    return response.data as IStreetTypesResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const streetTypesAdapter = createEntityAdapter<IStreetTypeItem>({
  selectId: (item) => item.name,
});

const streetTypesSlice = createSlice({
  name: "streetTypes",
  initialState: streetTypesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchstreetTypes.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(fetchstreetTypes.fulfilled, (state, action) => {
        streetTypesAdapter.addMany(state, action.payload.types.items);
        state.loading = "idle";
        state.error = null;
      })
      .addCase(fetchstreetTypes.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export const streetTypesSelectors = streetTypesAdapter.getSelectors<RootState>(
  (state: RootState) => state.streetTypes
);
export default streetTypesSlice.reducer;
