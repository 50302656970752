import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { uniqueId } from "lodash";
import { Link } from "react-router-dom";

type IActionMenuProperties = {
  title: string;
  url: string | null;
  action?: () => void;
};

function TableActionsMenu({ actions }: { actions: IActionMenuProperties[] }) {
  const items: MenuProps["items"] = actions.map((item) => {
    return {
      key: uniqueId(),
      label: item.action ? (
        <a href="#" onClick={() => item.action?.()}>
          {item.title}
        </a>
      ) : (
        item.url && <Link to={item.url}>{item.title}</Link>
      ),
    };
  });

  return (
    items && (
      <Dropdown menu={{ items }} placement="bottomRight">
        <Button type="text" icon={<EllipsisOutlined />} />
      </Dropdown>
    )
  );
}

export default TableActionsMenu;
