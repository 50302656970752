import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkCustomError } from "@/models/error";
import {
  IProgramListItem,
  IProgrammCreateRequest,
  IProgrammCreateResponse,
  IProgrammEditRequest,
  IProgrammResponse,
  IProgrammsResponse,
} from "@/models/programms";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IProgrammsState extends IEntitiesState {
  entities?: IProgramListItem[];
}

const initialState: IProgrammsState = {
  loading: "idle",
  error: null,
  success: null,
};

export const fetchProgramms = createAsyncThunk<
  IProgrammsResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("programms/fetchProgramms", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PROGRAMMS_URL());
    return response.data as IProgrammsResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchProgramm = createAsyncThunk<
  IProgrammResponse,
  string,
  { rejectValue: IThunkCustomError }
>("programms/fetchProgramm", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PROGRAMM_URL(coachCode));
    return response.data as IProgrammResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createProgramm = createAsyncThunk<
  IProgrammCreateResponse,
  IProgrammCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "programms/createProgramm",
  async (request: IProgrammCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        backendPaths.PROGRAMM_CREATE_URL(),
        JSON.stringify(request)
      );
      return response.data as IProgrammCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const editProgramm = createAsyncThunk<
  null,
  IProgrammEditRequest,
  { rejectValue: IThunkCustomError }
>(
  "programms/editProgramm",
  async (programm: IProgrammEditRequest, { rejectWithValue }) => {
    try {
      const coachCode = programm.code;
      const request: PartialBy<IProgrammEditRequest, "code"> = {
        ...programm,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.PROGRAMM_EDIT_URL(coachCode),
        JSON.stringify(request)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteProgramm = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>(
  "programms/deleteProgramm",
  async (coachCode: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        backendPaths.PROGRAMM_DELETE_URL(coachCode)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

const prorgammsAdapter = createEntityAdapter<IProgramListItem>({
  selectId: (item) => item.code,
});

const programmsSlice = createSlice({
  name: "programms",
  initialState: prorgammsAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch programs
      .addCase(fetchProgramms.fulfilled, (state, action) => {
        prorgammsAdapter.addMany(state, action.payload.programs.items);
      })
      // fetch one programm
      .addCase(fetchProgramm.fulfilled, (state, action) => {
        prorgammsAdapter.addOne(state, action.payload.program);
      })
      // create programm
      .addCase(createProgramm.fulfilled, (state, action) => {
        const newProgramm: IProgramListItem = {
          code: action.payload.code,
          name: action.meta.arg.name,
          description: action.meta.arg.description,
          duration: action.meta.arg.duration,
          calories: action.meta.arg.calories,
        };
        prorgammsAdapter.addOne(state, newProgramm);
        state.success = setSuccessMessage("Программа добавлена");
      })
      // edit programm
      .addCase(editProgramm.fulfilled, (state, action) => {
        const update = {
          id: action.meta.arg.code,
          changes: {
            name: action.meta.arg.name,
            description: action.meta.arg.description,
            duration: action.meta.arg.duration,
            calories: action.meta.arg.calories,
          },
        };
        prorgammsAdapter.updateOne(state, update);
        state.success = setSuccessMessage("Программа обновлена");
      })
      // delete programm
      .addCase(deleteProgramm.fulfilled, (state, action) => {
        prorgammsAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Программа удалена", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("programms", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("programms", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = programmsSlice.actions;
export const programmsSelectors = prorgammsAdapter.getSelectors<RootState>(
  (state: RootState) => state.programms
);
export default programmsSlice.reducer;
