import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IClubEditAddressValues } from "@/models/clubs";
import { IEditPageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import {
  editAddress,
  fetchClub,
  fetchClubAddress,
  resetErrors,
} from "@/slices/clubsSlice";
import {
  fetchstreetTypes,
  streetTypesSelectors,
} from "@/slices/streetTypesSlice";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

function ClubAddressEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    if (param.id) {
      void dispatch(resetErrors());
      void dispatch(fetchstreetTypes());
      void dispatch(fetchClub(param.id));
      void dispatch(fetchClubAddress(param.id));
    }
  }, []);

  const clubslData = useAppSelector(({ clubs }) => clubs);
  const { loading, error, success, clubDetail, clubAddress } = clubslData;
  const streetTypes = useAppSelector(streetTypesSelectors.selectAll).map(
    (item) => {
      return {
        value: item.name,
        label: item.title,
      };
    }
  );

  useEffect(() => {
    if (success) {
      setEditPageState(IEditPageState.success);
    } else if (loading === "loading") {
      setEditPageState(IEditPageState.loading);
    } else if (loading === "idle" && !error) {
      setEditPageState(IEditPageState.loaded);
    } else {
      setEditPageState(IEditPageState.error);
    }
  }, [loading, error, success]);

  const messages = setMessages([clubslData]);

  const handleUpdateClubAddress = (values: IClubEditAddressValues) => {
    console.log("values: ", values);
    if (param.id) {
      const request = {
        code: param.id,
        ...values,
      };
      void dispatch(editAddress(request));
    }
  };

  if (!param.id || error) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return clubDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.club_address_edit.title}: ${clubDetail.clubName}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="editClub"
          labelCol={form.LebelCol}
          wrapperCol={form.WrapperCol}
          autoComplete="off"
          initialValues={{
            city: clubAddress?.city,
            streetType: clubAddress?.streetType,
            street: clubAddress?.street,
            house: clubAddress?.house,
            entrance: clubAddress?.entrance,
            longtitude: clubAddress?.longtitude,
            latitude: clubAddress?.latitude,
          }}
          onFinish={handleUpdateClubAddress}
        >
          <Form.Item
            label="Город"
            name="city"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите город клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип улицы"
            name="streetType"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип улицы клуба",
              },
            ]}
          >
            <Select options={streetTypes} />
          </Form.Item>

          <Form.Item
            label="Наименование улицы"
            name="street"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите наименование улицы клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дом"
            name="house"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание дом клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Вход"
            name="entrance"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите вход клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Широта"
            name="latitude"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите широту клуба",
              },
            ]}
          >
            <InputNumber controls={false} precision={6} stringMode />
          </Form.Item>

          <Form.Item
            label="Долгота"
            name="longtitude"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите долготу клуба",
              },
            ]}
          >
            <InputNumber controls={false} precision={6} stringMode />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ClubAddressEdit;
