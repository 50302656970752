import { Button, Card, PageHeader, Switch, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import ShowMessages from "@/components/ShowMessages";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import IPageState from "@/models/pages";
import { IPromocodeListItem } from "@/models/promocodes";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import {
  deletePromocode,
  fetchPromocodes,
  promocodesSelectors,
} from "@/slices/promocodesSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function Promocodes() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);
  const [isPromocodeDeleteMode, setPromocodeDeleteMode] = useState(false);
  const [removablePromocode, setRemovablePromocode] =
    useState<IPromocodeListItem | null>(null);

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchPromocodes());
  }, []);

  const promocodesData = useAppSelector(({ promocodes }) => promocodes);
  const { loading, success, error } = promocodesData;
  const promocodes = addReactKey<IPromocodeListItem>(
    useAppSelector(promocodesSelectors.selectAll)
  );

  const messages = setMessages([promocodesData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && promocodes) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error]);

  // delete promocode
  const handleTurnOnPromocodeDeleteMode = (code: string) => {
    const promocodeToRemove = promocodes.find((item) => item.code === code);
    if (promocodeToRemove) {
      setPromocodeDeleteMode(true);
      setRemovablePromocode(promocodeToRemove);
    }
  };

  const handleDeleteProductSubmit = (code: string) => {
    void dispatch(deletePromocode(code));
    setPromocodeDeleteMode(false);
    setRemovablePromocode(null);
  };

  const handleDeleteProductCancel = () => {
    setPromocodeDeleteMode(false);
    setRemovablePromocode(null);
  };

  const columns: ColumnProps<IPromocodeListItem>[] = [
    {
      title: "Код",
      dataIndex: "secret",
      key: "secret",
    },
    {
      title: "Действует от",
      dataIndex: "startAt",
      key: "startAt",
      width: 130,
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Действует до",
      dataIndex: "endAt",
      key: "endAt",
      width: 130,
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Акции",
      dataIndex: "products",
      key: "products",
      // filters: clubsFilter,
      // ellipsis: true,
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.products.map((item) => `${item.title} `),
    },
    {
      title: "Активен",
      dataIndex: "active",
      key: "active",
      render: (_, record) => (
        <Switch size="small" checked={record.active} disabled />
      ),
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.promocode_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnPromocodeDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Промокоды"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.promocode_create.URL()}>
            <Button type="primary">
              {frontendPaths.promocode_create.title}
            </Button>
          </Link>
        }
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={promocodes}
          columns={columns}
          loading={isPageState === IPageState.loading}
        />
      </Card>
      {removablePromocode && (
        <DeleteConfirmModal
          isOpen={isPromocodeDeleteMode}
          onOk={() => handleDeleteProductSubmit(removablePromocode.code)}
          onCancel={handleDeleteProductCancel}
          text={`Удалить промокод: ${removablePromocode.secret}?`}
        />
      )}
    </>
  );
}

export default Promocodes;
