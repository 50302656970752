import { Card, Descriptions, PageHeader, Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import IPageState from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { fetchformQuerie } from "@/slices/formQueriesSlice";
import setMessages from "@/utils/setMessages";

function FormQueriesPage() {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);

  useEffect(() => {
    param.id && dispatch(fetchformQuerie(param.id));
  }, []);

  const formQuerie = useAppSelector(({ formQueries }) => formQueries);
  const { loading, success, error, formDetail } = formQuerie;

  const messages = setMessages([formQuerie]);

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setPageState(IPageState.loading);
    }
    if (loading === "idle") {
      console.log("loaded");
      setPageState(IPageState.loaded);
    }
    if (loading === "failed") {
      console.log("404");
      setPageState(IPageState.recordNotFound);
    }
    if (error) {
      console.log("error");
      setPageState(IPageState.error);
    }
    if (success) {
      console.log("success");
      setPageState(IPageState.success);
    }
  }, [loading, error, success]);

  return (
    <>
      <PageHeader
        title={frontendPaths.form_querie.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Descriptions>
          <Descriptions.Item label="createdAt">
            {formDetail?.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Имя">{formDetail?.name}</Descriptions.Item>
          <Descriptions.Item label="Телефон">
            {formDetail?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Клуб">{formDetail?.club}</Descriptions.Item>
          <Descriptions.Item label="Спец-предложение">
            {formDetail?.specialOffer}
          </Descriptions.Item>
          <Descriptions.Item label="Форма">
            <Tag>{formDetail?.type}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Пожелания">
            {formDetail?.note}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
}

export default FormQueriesPage;
