import { Modal } from "antd";

import { IAlertMessage } from "@/models/error";

import ShowMessages from "../ShowMessages";

interface IAddModalProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  text?: string;
  messages?: IAlertMessage[];
}

function DeleteConfirmModal({
  isOpen,
  onOk,
  onCancel,
  text,
  messages,
}: IAddModalProps) {
  return (
    <Modal title="Удаление" open={isOpen} onOk={onOk} onCancel={onCancel}>
      {messages && <ShowMessages messages={messages} />}
      <p>{text}</p>
    </Modal>
  );
}

export default DeleteConfirmModal;
