import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { axiosWithCredentials, uninterceptedAxios } from "../api/axios";
import backendPaths from "../constants/backendPaths";
import { ILoginResponse, IRefreshResponse, IUserRequest } from "../models/auth";
import { IThunkCustomError } from "../models/error";
import { IEntitiesState } from "../models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
} from "../utils/slicesMethods";

interface IAuth extends IEntitiesState {
  accessToken: string | null;
}

const initialState: IAuth = {
  accessToken: null,
  loading: "idle",
  error: null,
  success: null,
};

// хочу это переделать так чтобы кидал custom error
export const refreshToken = createAsyncThunk<
  IRefreshResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("auth/refreshToken", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithCredentials.post(
      backendPaths.REFRESH_TOKEN_URL()
    );
    return response.data as IRefreshResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const login = createAsyncThunk<
  ILoginResponse,
  IUserRequest,
  { rejectValue: IThunkCustomError }
>("auth/login", async (user: IUserRequest, { rejectWithValue }) => {
  try {
    const response = await uninterceptedAxios.post(
      backendPaths.LOGIN_URL(),
      JSON.stringify(user)
    );
    return response.data as ILoginResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const logOut = createAsyncThunk<
  IRefreshResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("auth/logOut", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.post(backendPaths.LOGOUT_URL());
    return response.data as IRefreshResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // refresh Token
      .addCase(refreshToken.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.token;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.accessToken = null;
        state.loading = "failed";
        state.error = action.payload;
      })
      // login user
      .addCase(login.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload.token;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.accessToken = null;
        state.loading = "failed";
        state.error = action.payload;
      })
      // logout
      .addCase(logOut.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.accessToken = null;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(logOut.rejected, (state, action) => {
        state.accessToken = null;
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
