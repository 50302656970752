import { Button, Card, Checkbox, Form, Input, PageHeader, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IDocEditRequest, IDocEditValues } from "@/models/docs";
import { IEditPageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { editDoc, fetchDoc } from "@/slices/docsSlice";
import setMessages from "@/utils/setMessages";

import { docsColorOptions } from "./DocCreate";
import PageNotFound from "./PageNotFound";

function DocEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && dispatch(fetchDoc(param.id));
  }, []);

  const docsData = useAppSelector(({ docs }) => docs);
  const { docDetail, loading, error, success } = docsData;

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && docDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [docDetail, loading, error, success]);

  const messages = setMessages([docsData]);

  const handleDocEdit = (values: IDocEditValues) => {
    if (docDetail) {
      const request: IDocEditRequest = {
        code: docDetail.code,
        ...values,
      };
      void dispatch(editDoc(request));
    }
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return docDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.doc_edit.title}: ${docDetail.name}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="editDoc"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleDocEdit}
          initialValues={{
            name: docDetail.name,
            color: docDetail.color,
            mainPageShow: docDetail.mainPageShow,
            description: docDetail.description,
            slug: docDetail.slug,
          }}
        >
          <Form.Item
            label="Заголовок"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок документа",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите slug",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Только латинские буквы и цифры",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Цвет"
            name="color"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цвет ярлыка документа",
              },
            ]}
          >
            <Select options={docsColorOptions} />
          </Form.Item>

          <Form.Item
            label="Показывать на главной?"
            name="mainPageShow"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание программы",
              },
            ]}
          >
            <TextEditor value="description" />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default DocEdit;
