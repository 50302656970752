import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { refreshToken } from "@/slices/authSlice";

function PersistLogin() {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(({ auth }) => auth);
  const [isTokenLoading, setIsTokenLoading] = useState(!accessToken);

  useEffect(() => {
    const veryfyRefreshToken = async () => {
      await dispatch(refreshToken());
      setIsTokenLoading(false);
    };
    isTokenLoading && veryfyRefreshToken();
  }, [isTokenLoading]);

  return isTokenLoading ? <Preloader message="Authorizing..." /> : <Outlet />;
}

export default PersistLogin;
