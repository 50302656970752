import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import clubsSlice from "./clubsSlice";
import coachsSlice from "./coachsSlice";
import docsSlice from "./docsSlice";
import formQueriesSlice from "./formQueriesSlice";
import newsSlice from "./newsSlice";
import offersSlice from "./offersSlice";
import optionIconsSlice from "./optionIconsSlice";
import ordersSlice from "./ordersSlice";
import productsSlice from "./productsSlice";
import programmsSlice from "./programmsSlice";
import promocodesSlice from "./promocodesSlice";
import scheduleSlice from "./scheduleSlice";
import slidersSlice from "./slidersSlice";
import streetTypesSlice from "./streetTypesSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    clubs: clubsSlice,
    optionIcons: optionIconsSlice,
    streetTypes: streetTypesSlice,
    coachs: coachsSlice,
    programms: programmsSlice,
    schedule: scheduleSlice,
    docs: docsSlice,
    news: newsSlice,
    products: productsSlice,
    promocodes: promocodesSlice,
    offers: offersSlice,
    orders: ordersSlice,
    formQueries: formQueriesSlice,
    sliders: slidersSlice,
  },
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
