import { DollarCircleOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader, Table, Tag, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
  ColumnFilterItem,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import ShowMessages from "@/components/ShowMessages";
import TableActionsMenu from "@/components/TableActionsMenu";
import dayOfWeek from "@/constants/daysOfWeek";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import IPageState from "@/models/pages";
import { IScheduleListItem } from "@/models/schedule";
import frontendPaths from "@/routes/paths";
import { fetchAllAreas } from "@/slices/clubsSlice";
import { coachsSelectors, fetchCoachs } from "@/slices/coachsSlice";
import { fetchProgramms, programmsSelectors } from "@/slices/programmsSlice";
import {
  deleteEvent,
  fetchEvents,
  resetErrors,
  scheduleSelectors,
  setFilters,
  setSorter,
} from "@/slices/scheduleSlice";
import addReactKey from "@/utils/addReactKey";
import getFullName from "@/utils/getFullName";
import setMessages from "@/utils/setMessages";
import { convertSorterConfig, getSorterOrder } from "@/utils/sorters";

function Schedule() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);
  const [isScheduleDeleteMode, setScheduleDeleteMode] = useState(false);
  const [removableSchedule, setRemovableSchedule] =
    useState<IScheduleListItem | null>(null);

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchEvents());
    void dispatch(fetchAllAreas());
    void dispatch(fetchCoachs());
    void dispatch(fetchProgramms());
  }, []);

  const eventsData = useAppSelector(({ schedule }) => schedule);
  const { loading, success, error } = eventsData;
  const events = addReactKey<IScheduleListItem>(
    useAppSelector(scheduleSelectors.selectAll)
  );

  const messages = setMessages([eventsData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && events) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error]);

  // delete event of Schedule
  const handleTurnOnScheduleDeleteMode = (code: string) => {
    const eventToRemove = events.find((item) => item.code === code);
    if (eventToRemove) {
      setScheduleDeleteMode(true);
      setRemovableSchedule(eventToRemove);
    }
  };

  const handleDeleteEventSubmit = (code: string) => {
    void dispatch(deleteEvent(code));
    setScheduleDeleteMode(false);
    setRemovableSchedule(null);
  };

  const handleDeleteEventCancel = () => {
    setScheduleDeleteMode(false);
    setRemovableSchedule(null);
  };

  const onTableChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IScheduleListItem> | SorterResult<IScheduleListItem>[]
  ) => {
    const storeFilters = eventsData.filters;
    const filtersChanged = !isEqual(filters, storeFilters);
    filtersChanged && dispatch(setFilters(filters));
    const storeSorter = eventsData.sorter;
    const convertedSorter = convertSorterConfig<IScheduleListItem>(sorter);
    const sorterChanged = !isEqual(storeSorter, convertedSorter);
    sorterChanged && dispatch(setSorter(convertedSorter));
  };

  const programmsFilter: ColumnFilterItem[] = useAppSelector(
    programmsSelectors.selectAll
  ).map((item) => {
    return {
      key: item.code,
      text: item.name,
      value: item.code,
    };
  });

  const daysFilter: ColumnFilterItem[] = dayOfWeek.map((item) => {
    return {
      key: item.value,
      text: item.label,
      value: item.value,
    };
  });

  const allAreas = useAppSelector(({ clubs }) => clubs).clubAllAreas;
  const areasFilter: ColumnFilterItem[] =
    allAreas?.map((item) => {
      return {
        key: item.code,
        text: `${item.name} (${item.club.name})`,
        value: item.code,
      };
    }) || [];

  const coachsFilter: ColumnFilterItem[] =
    useAppSelector(coachsSelectors.selectAll)
      .map((item) => {
        return {
          key: item.code,
          text: getFullName(item.firstName, item.lastName),
          value: item.code,
        };
      })
      .sort((a, b) => a.text.localeCompare(b.text)) || [];

  const columns: ColumnProps<IScheduleListItem>[] = [
    {
      title: "Время",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "День недели",
      dataIndex: "weekday",
      key: "weekday",
      sorter: (a, b) => a.weekday - b.weekday,
      defaultSortOrder: getSorterOrder(eventsData?.sorter, "weekday"),
      render: (_, record) => <Tag>{dayOfWeek[record.weekday]?.label}</Tag>,
      filters: daysFilter,
      defaultFilteredValue: eventsData.filters.weekday,
      onFilter: (value, record) => record.weekday === value,
    },
    {
      title: "Программа",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (_, record) => (
        <Tooltip title={record.program.description}>
          {record.program.name}
        </Tooltip>
      ),
      filters: programmsFilter,
      defaultFilteredValue: eventsData.filters.name,
      onFilter: (value, record) => record.program.code === value,
    },
    {
      title: "Зал",
      dataIndex: "area",
      key: "area",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => `${record.area.name} (${record.area.club.name})`,
      filters: areasFilter,
      defaultFilteredValue: eventsData.filters.area,
      onFilter: (value, record) => record.area.code === value,
    },
    {
      title: "Тренер",
      dataIndex: "teacher",
      key: "teacher",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) =>
        getFullName(record.teacher.firstName, record.teacher.lastName),
      filters: coachsFilter,
      defaultFilteredValue: eventsData.filters.teacher,
      onFilter: (value, record) => record.teacher.code === value,
    },
    {
      title: "Платная",
      dataIndex: "paid",
      key: "paid",
      align: "center",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.paid && <DollarCircleOutlined />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.schedule_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnScheduleDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Расписание"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.schedule_create.URL()}>
            <Button type="primary">
              {frontendPaths.schedule_create.title}
            </Button>
          </Link>
        }
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={events}
          columns={columns}
          loading={isPageState === IPageState.loading}
          onChange={onTableChange}
        />
      </Card>
      {removableSchedule && (
        <DeleteConfirmModal
          isOpen={isScheduleDeleteMode}
          onOk={() => handleDeleteEventSubmit(removableSchedule.code)}
          onCancel={handleDeleteEventCancel}
          text={`Удалить событие: ${removableSchedule.program.name}?`}
        />
      )}
    </>
  );
}

export default Schedule;
