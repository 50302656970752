import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import {
  convertIFileResponseToPhotoListItem,
  createInitUploadConfig,
} from "@/models/files";
import { IOfferEditArgs, IOfferEditValues } from "@/models/offers";
import { IEditPageState } from "@/models/pages";
import { resetErrors } from "@/slices/clubsSlice";
import { editOffer, fetchOffer } from "@/slices/offersSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

function OfferEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && dispatch(fetchOffer(param.id));
  }, []);

  const offerData = useAppSelector(({ offers }) => offers);
  const { loading, error, success, offerDetail } = offerData;

  const initFile = createInitUploadConfig(offerDetail?.photo);

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && offerDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [offerDetail, loading, error, success]);

  const messages = setMessages([offerData]);

  const handleOfferEdit = (values: IOfferEditValues) => {
    console.log("values: ", values);

    if (!offerDetail || !values.photo || !values.photo[0]?.response?.code)
      return;
    const request: IOfferEditArgs = {
      code: offerDetail.code,
      name: values.name,
      title: values.title,
      endAt: dayjs(values.endAt).format("YYYY-MM-DD hh:mm:ss"),
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(editOffer(request));
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return offerDetail ? (
    <>
      <PageHeader
        title={`Редактирование Спец-предложения: ${offerDetail.name}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        {offerDetail && (
          <Form
            name="editOffer"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              name: offerDetail.name,
              title: offerDetail.title,
              endAt: dayjs(offerDetail.endAt, "YYYY-MM-DD hh:mm:ss"),
              photo: initFile,
            }}
            onFinish={handleOfferEdit}
          >
            <Form.Item
              label="Метка"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите метку спец-предложения",
                },
                {
                  required: true,
                  pattern: new RegExp(slugRegExp),
                  message: "Пожалуйста метку латинскими буквами",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Заголовок"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок спец-предложения",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Дата до"
              name="endAt"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите дату завершения",
                },
              ]}
            >
              <DatePicker showTime />
            </Form.Item>

            <Form.Item
              label="Фото"
              name="photo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите фото спец-предложения",
                },
              ]}
            >
              <Upload
                accept="image/*"
                customRequest={customUpload}
                listType="picture-card"
                multiple={false}
                maxCount={1}
                defaultFileList={initFile}
              >
                <Button type="link">Загрузить</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </>
  ) : null;
}

export default OfferEdit;
