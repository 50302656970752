import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkCustomError } from "@/models/error";
import { IProductCreateResponse } from "@/models/products";
import {
  IPromocodeCreateRequest,
  IPromocodeCreateResponse,
  IPromocodeDetail,
  IPromocodeDetailResponse,
  IPromocodeEditRequest,
  IPromocodeListItem,
  IPromocodeListResponse,
} from "@/models/promocodes";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IPromocodesState extends IEntitiesState {
  entities?: IPromocodeListItem[];
  promocodeDetail: IPromocodeDetail | null;
}

const initialState: IPromocodesState = {
  loading: "idle",
  error: null,
  success: null,
  promocodeDetail: null,
};

export const fetchPromocodes = createAsyncThunk<
  IPromocodeListResponse,
  undefined,
  { rejectValue: IThunkCustomError }
>("promocodes/fetchPromocodes", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PROMOCODES_URL());
    return response.data as IPromocodeListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchPromocode = createAsyncThunk<
  IPromocodeDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("promocodes/fetchPromocode", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.PROMOCODE_URL(coachCode));
    return response.data as IPromocodeDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createPromocode = createAsyncThunk<
  IProductCreateResponse,
  IPromocodeCreateRequest,
  { rejectValue: IThunkCustomError }
>(
  "promocodes/createPromocode",
  async (request: IPromocodeCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        backendPaths.PROMOCODE_CREATE_URL(),
        JSON.stringify(request)
      );
      return response.data as IPromocodeCreateResponse;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const editPromocode = createAsyncThunk<
  null,
  IPromocodeEditRequest,
  { rejectValue: IThunkCustomError }
>(
  "promocodes/editPromocode",
  async (product: IPromocodeEditRequest, { rejectWithValue }) => {
    try {
      const promocodeCode = product.code;
      const request: PartialBy<IPromocodeEditRequest, "code"> = {
        ...product,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.PROMOCODE_EDIT_URL(promocodeCode),
        JSON.stringify(product)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deletePromocode = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>(
  "promocodes/deletePromocode",
  async (productCode: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        backendPaths.PROMOCODE_DELETE_URL(productCode)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

const promocodesAdapter = createEntityAdapter<IPromocodeListItem>({
  selectId: (item) => item.code,
});

const promocodesSlice = createSlice({
  name: "promocodes",
  initialState: promocodesAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch promocodes
      .addCase(fetchPromocodes.fulfilled, (state, action) => {
        promocodesAdapter.removeAll(state);
        promocodesAdapter.addMany(state, action.payload.promocodes.items);
      })
      // fetch one promocode
      .addCase(fetchPromocode.fulfilled, (state, action) => {
        state.promocodeDetail = action.payload.promocode;
      })
      // create promocode
      .addCase(createPromocode.fulfilled, (state) => {
        // const newProgramm: IProductListItem = {
        //   code: action.payload.code,
        //   title: action.meta.arg.title,
        //   price: action.meta.arg.price,
        //   active: action.meta.arg.active,
        //   club: action.meta.arg.clubCode, // загрузить клуб!
        // };
        // productsAdapter.addOne(state, newProgramm);
        state.success = setSuccessMessage("Промокод добавлен");
      })
      // edit promocode
      .addCase(editPromocode.fulfilled, (state, action) => {
        state.promocodeDetail = action.meta.arg;
        state.success = setSuccessMessage("Промокод обновлен");
      })
      // delete promocode
      .addCase(deletePromocode.fulfilled, (state, action) => {
        promocodesAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Промокод удален", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("promocodes", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("promocodes", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = promocodesSlice.actions;
export const promocodesSelectors = promocodesAdapter.getSelectors<RootState>(
  (state: RootState) => state.promocodes
);
export default promocodesSlice.reducer;
