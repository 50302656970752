import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { useAppDispatch } from "@/hooks/useReduxStoreHooks";
import AdminLayoutTemplate from "@/layouts/AdminLayoutTemplate";
import { fetchClubs } from "@/slices/clubsSlice";

function LoadDirectories() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  // грузим полезные справочники один раз
  useEffect(() => {
    const loadDirectories = async () => {
      await dispatch(fetchClubs());
      setIsLoading(false);
    };
    isLoading && loadDirectories();
  }, [isLoading]);

  return isLoading ? (
    <Preloader message="Loading directories..." />
  ) : (
    <AdminLayoutTemplate>
      <Outlet />
    </AdminLayoutTemplate>
  );
}

export default LoadDirectories;
