import { Alert, Typography } from "antd";
import { uniqueId } from "lodash";

import { IAlertMessage } from "@/models/error";

type IShowMessageProperties = {
  messages: IAlertMessage[];
};

const { Paragraph } = Typography;

function ShowMessages({ messages }: IShowMessageProperties): JSX.Element {
  return (
    <>
      {messages.map((message) => (
        <Alert
          key={uniqueId()}
          message={message.message}
          description={
            message.description && (
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                }}
              >
                {message.description}
              </Paragraph>
            )
          }
          type={message.type || "error"}
        />
      ))}
    </>
  );
}

export default ShowMessages;
