import { Card, PageHeader, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import { useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IClubListItem } from "@/models/clubs";
import IPageState from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { clubsSelectors } from "@/slices/clubsSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function Clubs() {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);

  const clubsData = useAppSelector(({ clubs }) => clubs);
  const { loading, success, error } = clubsData;
  const clubs = addReactKey(useAppSelector(clubsSelectors.selectAll));
  const messages = setMessages([clubsData]);

  useEffect(() => {
    // dispatch(resetErrors());
  }, []);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    } else if (loading === "idle" && clubs) {
      setPageState(IPageState.loaded);
    } else {
      setPageState(IPageState.error);
    }
  }, [loading, success, error]);

  const columns: ColumnProps<IClubListItem>[] = [
    {
      title: "Клуб",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Link to={frontendPaths.club.URL(record.code)}>{record.name}</Link>
      ),
    },
    {
      title: "Телефон",
      dataIndex: "contactPhone",
      key: "contactPhone",
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.clubs.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowMessages messages={messages} />
        <Table
          dataSource={clubs}
          columns={columns}
          pagination={false}
          loading={isPageState === IPageState.loading}
        />
      </Card>
    </>
  );
}

export default Clubs;
