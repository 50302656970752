import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import backendPaths from "@/constants/backendPaths";
import { defaultPageSize } from "@/constants/params";
import { IThunkCustomError } from "@/models/error";
import {
  IOfferCreateArgs,
  IOfferCreateRequest,
  IOfferDetail,
  IOfferDetailResponse,
  IOfferEditArgs,
  IOfferEditRequest,
  IOfferListItem,
  IOfferListResponse,
} from "@/models/offers";
import { IProductCreateResponse } from "@/models/products";
import { IPromocodeCreateResponse } from "@/models/promocodes";
import { IEntitiesState, PartialBy } from "@/models/slice";
import {
  assertAxiosError,
  convertErrToCustomError,
  isThunkActionError,
  isThunkActionFullfield,
  isThunkActionPending,
  setSuccessMessage,
} from "@/utils/slicesMethods";

import { RootState } from "./index";

interface IOffersState extends IEntitiesState {
  entities?: IOfferListItem[];
  offerDetail: IOfferDetail | null;
}

const initialState: IOffersState = {
  loading: "idle",
  error: null,
  success: null,
  offerDetail: null,
};

export const fetchOffers = createAsyncThunk<
  IOfferListResponse,
  number,
  { rejectValue: IThunkCustomError }
>("offers/fetchOffers", async (page, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.OFFERS_URL(), {
      params: { page, pageSize: defaultPageSize },
    });
    return response.data as IOfferListResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const fetchOffer = createAsyncThunk<
  IOfferDetailResponse,
  string,
  { rejectValue: IThunkCustomError }
>("offers/fetchOffer", async (coachCode, { rejectWithValue }) => {
  try {
    const response = await axios.get(backendPaths.OFFER_URL(coachCode));
    return response.data as IOfferDetailResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const createOffer = createAsyncThunk<
  IProductCreateResponse,
  IOfferCreateArgs,
  { rejectValue: IThunkCustomError }
>("offers/createOffer", async (args: IOfferCreateArgs, { rejectWithValue }) => {
  try {
    const backendRequest: IOfferCreateRequest = {
      name: args.name,
      title: args.title,
      endAt: args.endAt,
      photoCode: args.photo.code,
    };
    const response = await axios.post(
      backendPaths.OFFER_CREATE_URL(),
      JSON.stringify(backendRequest)
    );
    return response.data as IPromocodeCreateResponse;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

export const editOffer = createAsyncThunk<
  null,
  IOfferEditArgs,
  { rejectValue: IThunkCustomError }
>(
  "offers/editOffer",
  async (offerArgs: IOfferEditArgs, { rejectWithValue }) => {
    try {
      const { code } = offerArgs;
      const request: PartialBy<IOfferEditArgs, "code"> = {
        ...offerArgs,
      };
      delete request.code;
      const backendRequest: IOfferEditRequest = {
        ...request,
        photoCode: request.photo.code,
      };
      const response = await axios.post(
        backendPaths.OFFER_EDIT_URL(code),
        JSON.stringify(backendRequest)
      );
      return response.data as null;
    } catch (err) {
      assertAxiosError(err);
      return rejectWithValue(convertErrToCustomError(err));
    }
  }
);

export const deleteOffer = createAsyncThunk<
  null,
  string,
  { rejectValue: IThunkCustomError }
>("offers/deleteOffer", async (productCode: string, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      backendPaths.OFFER_DELETE_URL(productCode)
    );
    return response.data as null;
  } catch (err) {
    assertAxiosError(err);
    return rejectWithValue(convertErrToCustomError(err));
  }
});

const offersAdapter = createEntityAdapter<IOfferListItem>({
  selectId: (item) => item.code,
});

const offersSlice = createSlice({
  name: "offers",
  initialState: offersAdapter.getInitialState(initialState),
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch offers
      .addCase(fetchOffers.fulfilled, (state, action) => {
        offersAdapter.removeAll(state);
        offersAdapter.addMany(state, action.payload.specialOffer.items);
        state.pagination = action.payload.specialOffer.pagination;
      })
      // fetch one offer
      .addCase(fetchOffer.fulfilled, (state, action) => {
        state.offerDetail = action.payload.specialOffer;
      })
      // create offer
      .addCase(createOffer.fulfilled, (state, action) => {
        const newOffer: IOfferListItem = {
          code: action.payload.code,
          name: action.meta.arg.name,
          title: action.meta.arg.title,
          endAt: action.meta.arg.endAt,
          photo: action.meta.arg.photo,
        };
        offersAdapter.addOne(state, newOffer);
        state.success = setSuccessMessage("Спец-предложение добавлено");
      })
      // edit offer
      .addCase(editOffer.fulfilled, (state, action) => {
        state.offerDetail = action.meta.arg;
        state.success = setSuccessMessage("Спец-предложение обновлено");
      })
      // delete offer
      .addCase(deleteOffer.fulfilled, (state, action) => {
        offersAdapter.removeOne(state, action.meta.arg);
        state.success = setSuccessMessage("Спец-предложение удалено", true);
      })
      // matchers (common for all slices)
      .addMatcher(
        isThunkActionError,
        (state, action: PayloadAction<IThunkCustomError>) => {
          state.loading = "failed";
          state.error = action.payload;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionPending("offers", action),
        (state) => {
          state.loading = "loading";
          state.error = null;
          state.success = null;
        }
      )
      .addMatcher(
        (action: AnyAction) => isThunkActionFullfield("offers", action),
        (state) => {
          state.loading = "idle";
          state.error = null;
        }
      );
  },
});

export const { resetErrors } = offersSlice.actions;
export const offersSelectors = offersAdapter.getSelectors<RootState>(
  (state: RootState) => state.offers
);
export default offersSlice.reducer;
