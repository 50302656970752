import {
  Button,
  Card,
  Form,
  Input,
  PageHeader,
  Radio,
  Select,
  Upload,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { phoneRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { coachTypes, ICoachEditArgs, ICoachsEditValues } from "@/models/coachs";
import {
  convertIFileResponseToPhotoListItem,
  createInitUploadConfig,
} from "@/models/files";
import { IEditPageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { clubsSelectors, resetErrors } from "@/slices/clubsSlice";
import { editCoach, fetchCoach } from "@/slices/coachsSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import getFullName from "@/utils/getFullName";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function CoachEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && void dispatch(fetchCoach(param.id));
  }, []);

  // const clubsData = useAppSelector(({ clubs }) => clubs);
  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  const coachData = useAppSelector(({ coachs }) => coachs);
  const { coachDetail, loading, error, success } = coachData;

  const initFile = createInitUploadConfig(coachDetail?.photo);

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && coachDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [coachDetail, loading, error, success]);

  const messages = setMessages([coachData]);

  const handleCoachEdit = (values: ICoachsEditValues) => {
    if (!coachDetail || !values.photo || !values.photo[0]?.response?.code)
      return; // хорошо бы сообщить об ошибке

    const request: ICoachEditArgs = {
      code: coachDetail?.code,
      ...values,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
      clubs: clubs
        .filter((item) => values.clubCodes.includes(item.code))
        .map((item) => {
          return {
            code: item.code,
            name: item.name,
            contactPhone: item.contactPhone,
          };
        }),
    };
    void dispatch(editCoach(request));
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return coachDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.coach_edit.title}: ${getFullName(
          coachDetail.firstName,
          coachDetail.lastName
        )}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isEditPageState === IEditPageState.error ||
          isEditPageState === IEditPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="editCoach"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          initialValues={{
            firstName: coachDetail.firstName,
            lastName: coachDetail.lastName,
            phone: coachDetail.phone,
            socialTG: coachDetail.socialTG,
            socialVK: coachDetail.socialVK,
            clubCodes: coachDetail.clubCodes,
            shortBio: coachDetail.shortBio,
            description: coachDetail.description,
            jobType: coachDetail.jobType,
            photo: initFile,
            hideLastName: coachDetail.hideLastName,
          }}
          onFinish={handleCoachEdit}
        >
          <Form.Item
            label="Имя тренера"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите имя тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Фамилия тренера"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фамилию тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Скрывать фамилию на сайте"
            name="hideLastName"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Тип тренера"
            name="jobType"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип работы тренера",
              },
            ]}
          >
            <Radio.Group options={coachTypes} />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите телефон тренера",
              },
              {
                required: true,
                pattern: new RegExp(phoneRegExp),
                message: "Пожалуйста введите корректный телефон",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя телеграмм" name="socialTG">
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя вконтакте" name="socialVK">
            <Input />
          </Form.Item>

          <Form.Item label="Клубы тренера" name="clubCodes">
            <Select options={clubsOptions} mode="multiple" allowClear />
          </Form.Item>

          <Form.Item
            label="Краткое описание"
            name="shortBio"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите краткое описание тренера",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание тренера",
              },
            ]}
          >
            <TextEditor value="description" />
          </Form.Item>

          <Form.Item
            label="Фото тренера"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото тренера",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
              defaultFileList={initFile}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default CoachEdit;
