import { Tooltip } from "antd";
import dayjs from "dayjs";

import { dateFormatDate, dateFormatFull } from "@/constants/params";

type PropsType = {
  date: string;
};

function TooltipDate({ date }: PropsType) {
  return (
    <Tooltip title={dayjs(date).format(dateFormatFull)}>
      {dayjs(date).format(dateFormatDate)}
    </Tooltip>
  );
}

export default TooltipDate;
