import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { IEditPageState } from "@/models/pages";
import { IProductEditRequestArgs, IProductEditValues } from "@/models/products";
import { clubsSelectors } from "@/slices/clubsSlice";
import { editProduct, fetchProduct, resetErrors } from "@/slices/productsSlice";
import setMessages from "@/utils/setMessages";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function PromocodeEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [isEditPageState, setEditPageState] = useState<IEditPageState>(
    IEditPageState.loading
  );

  useEffect(() => {
    dispatch(resetErrors());
    param.id && dispatch(fetchProduct(param.id));
  }, []);

  const productData = useAppSelector(({ products }) => products);
  const { productDetail, loading, error, success } = productData;

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  useEffect(() => {
    if (loading === "loading") {
      console.log("loading");
      setEditPageState(IEditPageState.loading);
    }

    if (loading === "idle" && productDetail) {
      console.log("loaded");
      setEditPageState(IEditPageState.loaded);
    }

    if (loading === "failed") {
      console.log("404");
      setEditPageState(IEditPageState.recordNotFound);
    }

    if (error && error.code !== "404") {
      console.log("error");
      setEditPageState(IEditPageState.error);
    }

    if (success) {
      console.log("success");
      setEditPageState(IEditPageState.success);
    }
  }, [productDetail, loading, error, success]);

  const messages = setMessages([productData]);

  const handleProductEdit = (values: IProductEditValues) => {
    console.log("values: ", values);
    const filteredClub = clubs.filter((item) =>
      values.clubCodes.includes(item.code)
    );
    if (productDetail && filteredClub) {
      const request: IProductEditRequestArgs = {
        code: productDetail.code,
        active: values.active,
        title: values.title,
        oldPrice: values.oldPrice,
        price: values.price,
        description: values.description,
        promocode: values.promocode,
        clubs: filteredClub,
      };
      void dispatch(editProduct(request));
    }
  };

  if (!param.id || isEditPageState === IEditPageState.recordNotFound) {
    return <PageNotFound />;
  }

  if (isEditPageState === IEditPageState.loading) {
    return <Preloader />;
  }

  return productDetail ? (
    <>
      <PageHeader
        title={`Редактирование акции: ${productDetail.title}`}
        onBack={() => navigate(-1)}
      />
      {productDetail && (
        <Card>
          {(isEditPageState === IEditPageState.error ||
            isEditPageState === IEditPageState.success) && (
            <ShowMessages messages={messages} />
          )}
          <Form
            name="editProduct"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              active: productDetail.active,
              title: productDetail.title,
              oldPrice: productDetail.oldPrice,
              price: productDetail.price,
              description: productDetail.description,
              clubCodes: productDetail.clubs.map((club) => club.code),
              promocode: productDetail.promocode,
            }}
            onFinish={handleProductEdit}
          >
            <Form.Item
              label="Активна"
              name="active"
              valuePropName="checked"
              initialValue
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Заголовок акции"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок акции",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Старая цена" name="oldPrice">
              <InputNumber precision={2} stringMode />
            </Form.Item>

            <Form.Item
              label="Цена"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите цену",
                },
              ]}
            >
              <InputNumber precision={2} stringMode />
            </Form.Item>

            <Form.Item
              label="Разрешить промокоды"
              name="promocode"
              valuePropName="checked"
            >
              <Checkbox>Да</Checkbox>
            </Form.Item>

            <Form.Item
              label="Клубы"
              name="clubCodes"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите клубы",
                },
              ]}
            >
              <Select options={clubsOptions} mode="multiple" />
            </Form.Item>

            <Form.Item
              label="Описание акции"
              name="description"
              tooltip="Переводы строк преобразуются в галочки"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите описание акции",
                },
              ]}
            >
              <TextArea rows={4} showCount maxLength={150} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  ) : null;
}

export default PromocodeEdit;
