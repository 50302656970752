import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import setupAxios from "./api/axios";
import "./index.css";
import Router from "./routes/Router";
import store from "./slices/index";

setupAxios(store);

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {}; // eslint-disable-line no-empty-function
  console.error = () => {}; // eslint-disable-line no-empty-function
  console.debug = () => {}; // eslint-disable-line no-empty-function
}

// remove StrictMode on Production
if (process.env.NODE_ENV === "production") {
  ReactDOM.render(
    <Provider store={store}>
      <Router />
    </Provider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
