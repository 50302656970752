import { Button, Card, Form, Input, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { ICreatePageState } from "@/models/pages";
import { ISliderEditValues } from "@/models/sliders";
import frontendPaths from "@/routes/paths";
import { createSlider, resetErrors } from "@/slices/slidersSlice";
import setMessages from "@/utils/setMessages";

function SliderCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const sliderData = useAppSelector(({ sliders }) => sliders);
  const { loading, error, success } = sliderData;

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([sliderData]);

  const handleSliderCreate = (values: ISliderEditValues) => {
    void dispatch(createSlider(values));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.slider_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createSlider"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleSliderCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Подзаголовок"
            name="subTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите подзаголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Название кнопки"
            name="buttonTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="URL кнопки"
            name="buttonLink"
            extra="Для формы 'отправить заявку' укажите #send-request"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите URL кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default SliderCreate;
