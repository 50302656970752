import { Button, Card, PageHeader, Pagination, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ImagePreview } from "@/components/ImagePreview";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import ShowMessages from "@/components/ShowMessages";
import TableActionsMenu from "@/components/TableActionsMenu";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { INewsListItem } from "@/models/news";
import IPageState from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { deleteNews, fetchNews, newsSelectors } from "@/slices/newsSlice";
import addReactKey from "@/utils/addReactKey";
import setMessages from "@/utils/setMessages";

function News() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPageState, setPageState] = useState<IPageState>(IPageState.loading);
  const [isNewsDeleteMode, setNewsDeleteMode] = useState(false);
  const [removableNews, setRemovableNews] = useState<INewsListItem | null>(
    null
  );

  useEffect(() => {
    void dispatch(resetErrors());
    void dispatch(fetchNews(1));
  }, []);

  const newsData = useAppSelector(({ news }) => news);
  const { loading, success, error, pagination } = newsData;
  const news = addReactKey<INewsListItem>(
    useAppSelector(newsSelectors.selectAll)
  );

  const messages = setMessages([newsData]);

  useEffect(() => {
    if (loading === "loading") {
      setPageState(IPageState.loading);
    }
    if (loading === "idle" && news) {
      setPageState(IPageState.loaded);
    }
    if (error) {
      setPageState(IPageState.error);
    }
    if (success) {
      setPageState(IPageState.success);
    }
  }, [loading, success, error]);

  const onPageChange = (page: number) => {
    void dispatch(fetchNews(page));
  };

  // delete news
  const handleTurnOnNewsDeleteMode = (code: string) => {
    const newsToRemove = news.find((item) => item.code === code);
    if (newsToRemove) {
      setNewsDeleteMode(true);
      setRemovableNews(newsToRemove);
    }
  };

  const handleDeleteNewsSubmit = (code: string) => {
    void dispatch(deleteNews(code));
    setNewsDeleteMode(false);
    setRemovableNews(null);
  };

  const handleDeleteNewsCancel = () => {
    setNewsDeleteMode(false);
    setRemovableNews(null);
  };

  const columns: ColumnProps<INewsListItem>[] = [
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (_, record) =>
        record?.photo ? (
          <ImagePreview
            photoCode={record.photo.code}
            imageSrc={record.photo.urlPath}
          />
        ) : null,
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      render: (_, record) => <TooltipDate date={record.date} />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.news_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnNewsDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Новости"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.news_create.URL()}>
            <Button type="primary">{frontendPaths.news_create.title}</Button>
          </Link>
        }
      />
      <Card>
        {(isPageState === IPageState.error ||
          isPageState === IPageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Table
          dataSource={news}
          columns={columns}
          pagination={false}
          loading={isPageState === IPageState.loading}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      {removableNews && (
        <DeleteConfirmModal
          isOpen={isNewsDeleteMode}
          onOk={() => handleDeleteNewsSubmit(removableNews.code)}
          onCancel={handleDeleteNewsCancel}
          text={`Удалить новость: ${removableNews.title}?`}
        />
      )}
    </>
  );
}

export default News;
