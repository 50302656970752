import { IAlertMessage } from "../models/error";
import { IEntitiesState } from "../models/slice";

// формирует массив ошибок и успешных сообщений, на входе получает объекты из стора
const setMessages = (slices: IEntitiesState[]) => {
  const messages: IAlertMessage[] = [];

  slices.forEach((sliceObj) => {
    if (sliceObj.error) {
      let description = "";
      if (
        "details" in sliceObj.error &&
        !!sliceObj.error.details &&
        "trace" in sliceObj.error.details
      ) {
        description = sliceObj.error.details.trace;
      } else if (
        "details" in sliceObj.error &&
        !!sliceObj.error.details &&
        "violations" in sliceObj.error.details
      ) {
        description = sliceObj.error.details.violations
          .map((item) => `${item.field}: ${item.message}`)
          .join(", ");
      }
      const message: IAlertMessage = {
        message: sliceObj.error.message,
        type: sliceObj.error.type,
        description,
      };
      messages.push(message);
    }
    if (sliceObj.success) {
      const message: IAlertMessage = {
        message: sliceObj.success.message,
        type: sliceObj.success.type,
      };
      messages.push(message);
    }
  });

  return messages;
};

export default setMessages;
