import { Alert, Button, Card, Form, Input, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as form from "../constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "../hooks/useReduxStoreHooks";
import LayoutTemplate from "../layouts/PublicLayoutTemplate";
import { IUserRequest } from "../models/auth";
import frontendPaths from "../routes/paths";
import { login } from "../slices/authSlice";

type loginFormValues = {
  username: string;
  password: string;
};

type ILoginPageState = "unsent" | "sending" | "success" | "error";

const { Title } = Typography;

function Authorization() {
  const dispatch = useAppDispatch();
  const [loginPageState, setLoginPageState] =
    useState<ILoginPageState>("unsent");

  const navigate = useNavigate();

  const { loading, error, accessToken } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (loading === "loading") {
      setLoginPageState("sending");
    }
    if (loading === "idle" && accessToken !== null) {
      console.log("success");
      navigate(frontendPaths.main.URL(), { replace: true });
    }
    if (error) {
      setLoginPageState("error");
    }
  }, [loading, error]);

  const onFinish = (values: loginFormValues) => {
    const request: IUserRequest = {
      username: values.username,
      password: values.password,
    };

    void dispatch(login(request));
  };

  return (
    <LayoutTemplate>
      <Card>
        <Title>Authorization</Title>
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          {loginPageState === "error" && (
            <Alert message={error?.message} type="error" />
          )}
          <Form
            name="authorization"
            labelCol={form.LebelCol}
            wrapperCol={form.WrapperCol}
            onFinish={onFinish}
            autoComplete="off"
            disabled={loginPageState === "sending"}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card>
    </LayoutTemplate>
  );
}

export default Authorization;
