import { useEffect } from "react";

import useLogout from "@/hooks/useLogout";

function Logout() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, []);

  return null;
}

export default Logout;
