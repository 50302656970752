import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShowMessages from "@/components/ShowMessages";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/hooks/useReduxStoreHooks";
import { convertIFileResponseToPhotoListItem } from "@/models/files";
import { IOfferCreateArgs, IOfferEditValues } from "@/models/offers";
import { ICreatePageState } from "@/models/pages";
import frontendPaths from "@/routes/paths";
import { resetErrors } from "@/slices/clubsSlice";
import { createOffer } from "@/slices/offersSlice";
import customUpload, { normFile } from "@/utils/customUpload";
import setMessages from "@/utils/setMessages";

function OfferCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [isCreatePageState, setCreatePageState] = useState<ICreatePageState>(
    ICreatePageState.unsent
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const offersData = useAppSelector(({ offers }) => offers);
  const { loading, error, success } = offersData;

  useEffect(() => {
    if (error) {
      console.log("error");
      setCreatePageState(ICreatePageState.error);
    }
    if (success) {
      console.log("success");
      setCreatePageState(ICreatePageState.success);
    }
  }, [loading, error, success]);

  const messages = setMessages([offersData]);

  const handleOfferCreate = (values: IOfferEditValues) => {
    if (!values.photo || !values.photo[0]?.response?.code) return;

    const args: IOfferCreateArgs = {
      name: values.name,
      title: values.title,
      endAt: dayjs(values.endAt).format("YYYY-MM-DD hh:mm:ss"),
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(createOffer(args));
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.offer_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        {(isCreatePageState === ICreatePageState.error ||
          isCreatePageState === ICreatePageState.success) && (
          <ShowMessages messages={messages} />
        )}
        <Form
          name="createOffer"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleOfferCreate}
          disabled={isCreatePageState === ICreatePageState.success}
        >
          <Form.Item
            label="Метка"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите метку спец-предложения",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Пожалуйста метку латинскими буквами",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок спец-предложения",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дата до"
            name="endAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату завершения",
              },
            ]}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label="Фото"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото спец-предложения",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default OfferCreate;
